// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Bounded from "../Data.Bounded/index.js";
import * as Data_Bounded_Generic from "../Data.Bounded.Generic/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Enum_Generic from "../Data.Enum.Generic/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Set_NonEmpty from "../Data.Set.NonEmpty/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Random from "../Effect.Random/index.js";
import * as Functions from "../Functions/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var eq = /* #__PURE__ */ Data_Eq.eq(Functions.eqFun);
var genericEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericEnumConstructor(Data_Enum_Generic.genericEnumNoArguments);
var genericTopConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericTopConstructor(Data_Bounded_Generic.genericTopNoArguments);
var genericEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericEnumSum(genericEnumConstructor)(genericTopConstructor);
var genericBottomConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericBottomConstructor(Data_Bounded_Generic.genericBottomNoArguments);
var genericBottomSum = /* #__PURE__ */ Data_Bounded_Generic.genericBottomSum(genericBottomConstructor);
var genericEnumSum1 = /* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(genericEnumConstructor)(genericBottomConstructor))(genericBottomSum))(genericBottomSum);
var genericBoundedEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericBoundedEnumConstructor(Data_Enum_Generic.genericBoundedEnumNoArguments);
var genericBoundedEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericBoundedEnumSum(genericBoundedEnumConstructor);
var genericBoundedEnumSum1 = /* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(genericBoundedEnumConstructor)));
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var sortWith = /* #__PURE__ */ Data_Array.sortWith(Data_Ord.ordNumber);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Effect.applicativeEffect);
var eq2 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(Functions.eqFun));
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var toUnfoldable = /* #__PURE__ */ Data_Set_NonEmpty.toUnfoldable(Data_Unfoldable.unfoldableArray);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Either.bindEither);
var A = /* #__PURE__ */ (function () {
    function A() {

    };
    A.value = new A();
    return A;
})();
var B = /* #__PURE__ */ (function () {
    function B() {

    };
    B.value = new B();
    return B;
})();
var C = /* #__PURE__ */ (function () {
    function C() {

    };
    C.value = new C();
    return C;
})();
var D = /* #__PURE__ */ (function () {
    function D() {

    };
    D.value = new D();
    return D;
})();
var AnsweredQuestion = /* #__PURE__ */ (function () {
    function AnsweredQuestion(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AnsweredQuestion.create = function (value0) {
        return function (value1) {
            return new AnsweredQuestion(value0, value1);
        };
    };
    return AnsweredQuestion;
})();
var genericOption_ = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return A.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return B.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return C.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return D.value;
        };
        throw new Error("Failed pattern match at Questions (line 41, column 1 - line 41, column 33): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof A) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof B) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof C) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof D) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at Questions (line 41, column 1 - line 41, column 33): " + [ x.constructor.name ]);
    }
};
var showOption = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericOption_)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "A";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "B";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "C";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "D";
        }
    })))))
};
var genericAnsweredQuestion_ = {
    to: function (x) {
        return new AnsweredQuestion(x.value0, x.value1);
    },
    from: function (x) {
        return new Data_Generic_Rep.Product(x.value0, x.value1);
    }
};
var showAnsweredQuestion = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericAnsweredQuestion_)(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsProduct(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "correctOption";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "optionA";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "optionB";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "optionC";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "optionD";
        }
    })(Data_Show.showRecordFieldsNil)(Functions.showFun))(Functions.showFun))(Functions.showFun))(Functions.showFun))(showOption))))(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(showOption)))({
        reflectSymbol: function () {
            return "AnsweredQuestion";
        }
    }))
};
var eqOption = {
    eq: function (x) {
        return function (y) {
            if (x instanceof A && y instanceof A) {
                return true;
            };
            if (x instanceof B && y instanceof B) {
                return true;
            };
            if (x instanceof C && y instanceof C) {
                return true;
            };
            if (x instanceof D && y instanceof D) {
                return true;
            };
            return false;
        };
    }
};
var eq3 = /* #__PURE__ */ Data_Eq.eq(eqOption);
var ordOption = {
    compare: function (x) {
        return function (y) {
            if (x instanceof A && y instanceof A) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof A) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof A) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof B && y instanceof B) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof B) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof B) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof C && y instanceof C) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof C) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof C) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof D && y instanceof D) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Questions (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqOption;
    }
};
var eqAnsweredQuestion = {
    eq: function (x) {
        return function (y) {
            return eq3(x.value0.correctOption)(y.value0.correctOption) && eq(x.value0.optionA)(y.value0.optionA) && eq(x.value0.optionB)(y.value0.optionB) && eq(x.value0.optionC)(y.value0.optionC) && eq(x.value0.optionD)(y.value0.optionD) && eq3(x.value1)(y.value1);
        };
    }
};
var enumOption = {
    succ: /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericOption_)(genericEnumSum1),
    pred: /* #__PURE__ */ Data_Enum_Generic.genericPred(genericOption_)(genericEnumSum1),
    Ord0: function () {
        return ordOption;
    }
};
var boundedOption = {
    bottom: /* #__PURE__ */ Data_Bounded_Generic.genericBottom(genericOption_)(genericBottomSum),
    top: /* #__PURE__ */ Data_Bounded_Generic.genericTop(genericOption_)(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(genericTopConstructor)))),
    Ord0: function () {
        return ordOption;
    }
};
var boundedEnumOption = {
    cardinality: /* #__PURE__ */ Data_Enum_Generic.genericCardinality(genericOption_)(genericBoundedEnumSum1),
    toEnum: /* #__PURE__ */ Data_Enum_Generic.genericToEnum(genericOption_)(genericBoundedEnumSum1),
    fromEnum: /* #__PURE__ */ Data_Enum_Generic.genericFromEnum(genericOption_)(genericBoundedEnumSum1),
    Bounded0: function () {
        return boundedOption;
    },
    Enum1: function () {
        return enumOption;
    }
};
var toEnum = /* #__PURE__ */ Data_Enum.toEnum(boundedEnumOption);
var toOptions = function (q) {
    return [ q.optionA, q.optionB, q.optionC, q.optionD ];
};
var shuffle = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(dictMonadEffect);
    return function (xs) {
        return liftEffect(map((function () {
            var $214 = map1(Data_Tuple.fst);
            var $215 = sortWith(Data_Tuple.snd);
            return function ($216) {
                return $214($215($216));
            };
        })())(traverse(function (x) {
            return map(Data_Tuple.Tuple.create(x))(Effect_Random.random);
        })(xs)));
    };
};
var questionFunction = function (q) {
    if (q.correctOption instanceof A) {
        return q.optionA;
    };
    if (q.correctOption instanceof B) {
        return q.optionB;
    };
    if (q.correctOption instanceof C) {
        return q.optionC;
    };
    if (q.correctOption instanceof D) {
        return q.optionD;
    };
    throw new Error("Failed pattern match at Questions (line 130, column 3 - line 134, column 19): " + [ q.correctOption.constructor.name ]);
};
var isAnswerCorrect = function (v) {
    return eq3(v.value0.correctOption)(v.value1);
};
var toStat = function (questions) {
    var countTotal = Data_Array.length(questions);
    var countCorrect = Data_Array.length(Data_Array.filter(isAnswerCorrect)(questions));
    var score = Data_Int.toNumber(countCorrect) / Data_Int.toNumber(countTotal);
    return {
        countTotal: countTotal,
        countCorrect: countCorrect,
        score: score
    };
};
var hasAnswerConflict = function (v) {
    return function (v1) {
        var isMathFn2 = function (v2) {
            if (v2 === "Ord a => a -> a -> a") {
                return true;
            };
            if (v2 === "Integral a => a -> a -> a") {
                return true;
            };
            if (v2 === "Floating a => a -> a -> a") {
                return true;
            };
            if (v2 === "RealFloat a => a -> a -> a") {
                return true;
            };
            if (v2 === "Number -> Number -> Number") {
                return true;
            };
            if (v2 === "Integer -> Integer -> Integer") {
                return true;
            };
            if (v2 === "Int -> Int -> Int") {
                return true;
            };
            if (v2 === "Float -> Float -> Float") {
                return true;
            };
            if (v2 === "number -> number -> number") {
                return true;
            };
            return false;
        };
        var isMathFn1 = function (v2) {
            if (v2 === "Ord a => a -> a") {
                return true;
            };
            if (v2 === "Integral a => a -> a") {
                return true;
            };
            if (v2 === "Floating a => a -> a") {
                return true;
            };
            if (v2 === "RealFloat a => a -> a") {
                return true;
            };
            if (v2 === "Number -> Number") {
                return true;
            };
            if (v2 === "Integer -> Integer") {
                return true;
            };
            if (v2 === "Int -> Int") {
                return true;
            };
            if (v2 === "Float -> Float") {
                return true;
            };
            if (v2 === "number -> number") {
                return true;
            };
            if (v2 === "Number -> Int") {
                return true;
            };
            return false;
        };
        var hasSignatureConflict = function (s1) {
            return function (s2) {
                return s1 === s2 || (isMathFn1(s1) && isMathFn1(s2) || isMathFn2(s1) && isMathFn2(s2));
            };
        };
        return v.name === v1.name || hasSignatureConflict(v.signature)(v1.signature);
    };
};
var hasAllowedLength = function (v) {
    return Data_String_CodePoints.length(v.signature) < 100;
};
var mkQuestions = function (dictMonadEffect) {
    var Monad0 = dictMonadEffect.Monad0();
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    var Bind1 = Monad0.Bind1();
    var bind = Control_Bind.bind(Bind1);
    var liftEffect = Effect_Class.liftEffect(dictMonadEffect);
    var bindFlipped1 = Control_Bind.bindFlipped(Bind1);
    var shuffle1 = shuffle(dictMonadEffect);
    return function (numQuestions) {
        return function (v) {
            if (numQuestions <= 0) {
                return pure(new Data_Either.Left("numQuestions <= 0"));
            };
            var questionIsValid = function (q) {
                return eq2(toOptions(q))(Data_Array.nubByEq(hasAnswerConflict)(toOptions(q)));
            };
            var mkQuestion = function (dictMonadEffect1) {
                return function (v1) {
                    if (v1.length === 4) {
                        return bind(liftEffect(map(toEnum)(Effect_Random.randomInt(0)(3))))(function (o) {
                            return pure((function () {
                                if (o instanceof Data_Maybe.Just) {
                                    return new Data_Either.Right({
                                        correctOption: o.value0,
                                        optionA: v1[0],
                                        optionB: v1[1],
                                        optionC: v1[2],
                                        optionD: v1[3]
                                    });
                                };
                                if (o instanceof Data_Maybe.Nothing) {
                                    return new Data_Either.Left("Could not create correctOption");
                                };
                                throw new Error("Failed pattern match at Questions (line 108, column 10 - line 110, column 55): " + [ o.constructor.name ]);
                            })());
                        });
                    };
                    return pure(new Data_Either.Left("Could not create 4 options"));
                };
            };
            var mkQuestion1 = mkQuestion(dictMonadEffect);
            var mkQuestionsRec = function (dictMonadEffect1) {
                return function (v1) {
                    return function (v2) {
                        if (v1 === 0) {
                            return pure(new Data_Either.Right([  ]));
                        };
                        var v3 = Data_Array.splitAt(4)(v2);
                        return bind(mkQuestion1(v3.before))(function (x) {
                            if (x instanceof Data_Either.Left) {
                                return pure(new Data_Either.Left(x.value0));
                            };
                            if (x instanceof Data_Either.Right && !questionIsValid(x.value0)) {
                                return bindFlipped1(mkQuestionsRec(dictMonadEffect)(v1))(shuffle1(v2));
                            };
                            if (x instanceof Data_Either.Right) {
                                return bind(mkQuestionsRec(dictMonadEffect)(v1 - 1 | 0)(v3.after))(function (otherQuestions) {
                                    return pure(map2(Data_Array.cons(x.value0))(otherQuestions));
                                });
                            };
                            throw new Error("Failed pattern match at Questions (line 95, column 5 - line 101, column 43): " + [ x.constructor.name ]);
                        });
                    };
                };
            };
            var mkQuestionsRec1 = mkQuestionsRec(dictMonadEffect);
            var arr = Data_Array.filter(hasAllowedLength)(toUnfoldable(v));
            return bind(shuffle1(arr))(function (shuffles) {
                return bind(mkQuestionsRec1(numQuestions)(shuffles))(function (qs) {
                    return pure(bindFlipped((function () {
                        var $217 = Data_Either.note("No questions");
                        return function ($218) {
                            return $217(Data_Array_NonEmpty.fromArray($218));
                        };
                    })())(qs));
                });
            });
        };
    };
};
var abcd = /* #__PURE__ */ Data_Enum.enumFromTo(enumOption)(Data_Array_NonEmpty_Internal.unfoldable1NonEmptyArray)(/* #__PURE__ */ Data_Bounded.bottom(boundedOption))(/* #__PURE__ */ Data_Bounded.top(boundedOption));
export {
    mkQuestions,
    questionFunction,
    isAnswerCorrect,
    abcd,
    toOptions,
    toStat,
    A,
    B,
    C,
    D,
    AnsweredQuestion,
    eqOption,
    ordOption,
    genericOption_,
    boundedOption,
    boundedEnumOption,
    enumOption,
    showOption,
    eqAnsweredQuestion,
    genericAnsweredQuestion_,
    showAnsweredQuestion
};
