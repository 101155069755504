// Generated by purs version 0.15.4
import * as Components_AppGameSteps from "../Components.AppGameSteps/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Effect from "../Effect/index.js";
import * as Foreign_Daisyui from "../Foreign.Daisyui/index.js";
import * as Foreign_ReactHotkeysHook from "../Foreign.ReactHotkeysHook/index.js";
import * as Functions from "../Functions/index.js";
import * as Languages from "../Languages/index.js";
import * as Questions from "../Questions/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Events from "../React.Basic.DOM.Events/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Events from "../React.Basic.Events/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as React_Icons from "../React.Icons/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var span = /* #__PURE__ */ React_Basic_DOM_Generated.span();
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupString);
var div = /* #__PURE__ */ React_Basic_DOM_Generated.div();
var h1 = /* #__PURE__ */ React_Basic_DOM_Generated.h1();
var icon = /* #__PURE__ */ React_Icons.icon();
var eq = /* #__PURE__ */ Data_Eq.eq(Questions.eqOption);
var notEq = /* #__PURE__ */ Data_Eq.notEq(Questions.eqOption);
var button_ = /* #__PURE__ */ Foreign_Daisyui.button_();
var when = /* #__PURE__ */ Control_Applicative.when(Effect.applicativeEffect);
var show = /* #__PURE__ */ Data_Show.show(Questions.showOption);
var guard = /* #__PURE__ */ Data_Monoid.guard(Data_Monoid.monoidString);
var code = /* #__PURE__ */ React_Basic_DOM_Generated.code();
var h2 = /* #__PURE__ */ React_Basic_DOM_Generated.h2();
var discard = /* #__PURE__ */ React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var OptionError = /* #__PURE__ */ (function () {
    function OptionError() {

    };
    OptionError.value = new OptionError();
    return OptionError;
})();
var OptionCorrect = /* #__PURE__ */ (function () {
    function OptionCorrect() {

    };
    OptionCorrect.value = new OptionCorrect();
    return OptionCorrect;
})();
var OptionDefault = /* #__PURE__ */ (function () {
    function OptionDefault() {

    };
    OptionDefault.value = new OptionDefault();
    return OptionDefault;
})();
var eqOptionResult = {
    eq: function (x) {
        return function (y) {
            if (x instanceof OptionError && y instanceof OptionError) {
                return true;
            };
            if (x instanceof OptionCorrect && y instanceof OptionCorrect) {
                return true;
            };
            if (x instanceof OptionDefault && y instanceof OptionDefault) {
                return true;
            };
            return false;
        };
    }
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(eqOptionResult);
var noParens = function (x) {
    return Data_Maybe.fromMaybe(x)(bindFlipped(Data_String_CodeUnits.stripPrefix("("))(Data_String_CodeUnits.stripSuffix(")")(x)));
};
var functionName = /* #__PURE__ */ (function () {
    var $57 = Data_Newtype.un()(Functions.Fun);
    return function ($58) {
        return (function (v) {
            return v.name;
        })($57($58));
    };
})();
var pageGameInProgress = function (v) {
    var renderConstraint = function (a) {
        return span({
            className: "opacity-70",
            children: [ React_Basic_DOM.text(a) ]
        });
    };
    var renderSignature = function (v1) {
        var v2 = Data_String_Common.split("=>")(v1.signature);
        if (v2.length === 2) {
            return React_Basic.fragment([ renderConstraint(v2[0] + "=>"), React_Basic_DOM.text(v2[1]) ]);
        };
        if (v2.length === 3) {
            return React_Basic.fragment([ renderConstraint(v2[0] + "=>"), renderConstraint(v2[1] + "=>"), React_Basic_DOM.text(v2[2]) ]);
        };
        return React_Basic_DOM.text(v1.signature);
    };
    var renderQuestion = function (q) {
        return div({
            className: "flex flex-col items-center justify-center w-full",
            children: [ h1({
                className: "flex flex-col font-mono text-xl font-medium gap-2 sm:text-2xl",
                children: [ renderSignature(Questions.questionFunction(q)) ]
            }) ]
        });
    };
    var renderCard = function (children) {
        return div({
            className: "flex-1 w-full max-w-2xl mx-2 shadow-xl card sm:flex-initial sm:min-h-[440px] bg-base-200",
            children: [ div({
                className: "z-10 items-center justify-between text-center card-body gap-4",
                children: children
            }), icon(Languages.languageIcon(v.language))({
                size: "80px",
                className: "absolute z-0 m-6 text-base-300"
            }) ]
        });
    };
    var optionResult = function (option) {
        if (v.inProgressState.currentAnswer instanceof Data_Maybe.Just && (eq(v.inProgressState.currentAnswer.value0)(option) && notEq(option)(v.inProgressState.currentQuestion.correctOption))) {
            return OptionError.value;
        };
        if (v.inProgressState.currentAnswer instanceof Data_Maybe.Just && eq(option)(v.inProgressState.currentQuestion.correctOption)) {
            return OptionCorrect.value;
        };
        return OptionDefault.value;
    };
    var renderAnswerButton = function (option) {
        return function (fun) {
            return button_({
                color: (function () {
                    var v1 = optionResult(option);
                    if (v1 instanceof OptionError) {
                        return "error";
                    };
                    if (v1 instanceof OptionCorrect) {
                        return "success";
                    };
                    if (v1 instanceof OptionDefault) {
                        return "default";
                    };
                    throw new Error("Failed pattern match at Components.PageGameInProgress (line 117, column 16 - line 120, column 37): " + [ v1.constructor.name ]);
                })(),
                onClick: React_Basic_Events.handler(React_Basic_DOM_Events.stopPropagation)(Data_Function["const"](when(Data_Maybe.isNothing(v.inProgressState.currentAnswer))(v.onAnswerClick(option)))),
                key: show(option) + functionName(fun),
                className: "justify-start w-64 m-2 gap-4 flex-nowrap" + (guard(Data_Maybe.isJust(v.inProgressState.currentAnswer))(" pointer-events-none") + guard(eq1(optionResult(option))(OptionError.value))(" animate-wiggle")),
                children: [ React_Basic.element(Foreign_Daisyui.badge)({
                    size: "lg",
                    responsive: false,
                    className: "",
                    color: (function () {
                        var v1 = optionResult(option);
                        if (v1 instanceof OptionDefault) {
                            return "secondary";
                        };
                        return "neutral";
                    })(),
                    children: [ React_Basic_DOM.text(show(option)) ]
                }), code({
                    className: "font-mono text-lg font-medium normal-case truncate",
                    title: functionName(fun),
                    children: [ React_Basic_DOM.text(noParens(functionName(fun))) ]
                }) ]
            });
        };
    };
    var cardContent = [ h2({
        className: "select-none card-title text-secondary whitespace-nowrap",
        children: [ React_Basic_DOM.text("Which function has this type?") ]
    }), renderQuestion(v.inProgressState.currentQuestion), div({
        key: Data_Function.on(append)(functionName)(v.inProgressState.currentQuestion.optionA)(v.inProgressState.currentQuestion.optionB),
        children: [ renderAnswerButton(Questions.A.value)(v.inProgressState.currentQuestion.optionA), renderAnswerButton(Questions.B.value)(v.inProgressState.currentQuestion.optionB), renderAnswerButton(Questions.C.value)(v.inProgressState.currentQuestion.optionC), renderAnswerButton(Questions.D.value)(v.inProgressState.currentQuestion.optionD) ]
    }) ];
    return div({
        className: "flex flex-col items-center justify-start w-full h-full gap-8 sm:justify-center",
        children: [ Components_AppGameSteps.appGameSteps({
            inProgressState: v.inProgressState
        }), renderCard(cardContent) ]
    });
};
var mkPageGameInProgress = /* #__PURE__ */ React_Basic_Hooks.component("PageGameInProgress")(function (v) {
    return discard(Foreign_ReactHotkeysHook.useHotkeys("a")(v.onAnswerClick(Questions.A.value)))(function () {
        return discard(Foreign_ReactHotkeysHook.useHotkeys("b")(v.onAnswerClick(Questions.B.value)))(function () {
            return discard(Foreign_ReactHotkeysHook.useHotkeys("c")(v.onAnswerClick(Questions.C.value)))(function () {
                return discard(Foreign_ReactHotkeysHook.useHotkeys("d")(v.onAnswerClick(Questions.D.value)))(function () {
                    return pure(pageGameInProgress(v));
                });
            });
        });
    });
});
export {
    mkPageGameInProgress
};
