// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
var urls = {
    haskell: $foreign.haskellFunctionsUrl,
    haskellLens: $foreign.haskellLensUrl,
    purescript: $foreign.purescriptFunctionsUrl,
    elm: $foreign.elmCoreUrl
};
export {
    elmCoreUrl,
    purescriptFunctionsUrl,
    haskellFunctionsUrl,
    haskellLensUrl
} from "./foreign.js";
export {
    urls
};
