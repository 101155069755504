// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Foreign_Daisyui from "../Foreign.Daisyui/index.js";
import * as Foreign_Logo from "../Foreign.Logo/index.js";
import * as Foreign_ReactHotkeysHook from "../Foreign.ReactHotkeysHook/index.js";
import * as Languages from "../Languages/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Events from "../React.Basic.Events/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as React_Icons from "../React.Icons/index.js";
import * as React_Icons_Vsc from "../React.Icons.Vsc/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
var eq = /* #__PURE__ */ Data_Eq.eq(Languages.eqLanguage);
var button_ = /* #__PURE__ */ Foreign_Daisyui.button_();
var show = /* #__PURE__ */ Data_Show.show(Languages.showLanguage);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showBoolean);
var icon = /* #__PURE__ */ React_Icons.icon();
var div = /* #__PURE__ */ React_Basic_DOM_Generated.div();
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var img = /* #__PURE__ */ React_Basic_DOM_Generated.img();
var discard = /* #__PURE__ */ React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var languageSelection = function (v) {
    var opactiy = function (l) {
        var $15 = eq(l)(v.language);
        if ($15) {
            return "opacity-100";
        };
        return "opacity-50";
    };
    var classes = function (l) {
        return Data_String_Common.joinWith(" ")([ "flex-col gap-2 min-w-fit w-24 h-20", opactiy(l) ]);
    };
    var languageButton = function (l) {
        return button_({
            color: (function () {
                var $16 = eq(l)(v.language);
                if ($16) {
                    return "secondary";
                };
                return "default";
            })(),
            onClick: React_Basic_Events.handler_(v.onLanguageSet(l)),
            key: Data_String_Common.joinWith("-")([ show(l), show1(eq(l)(v.language)) ]),
            className: classes(l),
            animation: false,
            children: [ icon(Languages.languageIcon(l))({
                size: "24px"
            }), React_Basic_DOM.text(show(l)) ]
        });
    };
    return div({
        className: "flex flex-row flex-nowrap btn-group",
        children: map(languageButton)([ Languages.Haskell.value, Languages.PureScript.value, Languages.Elm.value ])
    });
};
var pageStart = function (v) {
    return React_Basic.fragment([ img({
        src: Foreign_Logo.logoLarge,
        width: "360px",
        height: "360px",
        className: "animate-fadein pb-8"
    }), languageSelection({
        language: v.language,
        onLanguageSet: v.onLanguageSet
    }), button_({
        color: "primary",
        onClick: React_Basic_Events.handler_(v.onStartClick),
        key: "start",
        className: "mt-8 gap-2",
        children: [ React_Icons.icon_(React_Icons_Vsc.vscDebugStart), React_Basic_DOM.text("Start") ]
    }) ]);
};
var mkPageStart = /* #__PURE__ */ React_Basic_Hooks.component("PageStart")(function (v) {
    return discard(Foreign_ReactHotkeysHook.useHotkeys("space, return, s")(v.onStartClick))(function () {
        return discard(Foreign_ReactHotkeysHook.useHotkeys("h")(v.onLanguageSet(Languages.Haskell.value)))(function () {
            return discard(Foreign_ReactHotkeysHook.useHotkeys("p")(v.onLanguageSet(Languages.PureScript.value)))(function () {
                return discard(Foreign_ReactHotkeysHook.useHotkeys("e")(v.onLanguageSet(Languages.Elm.value)))(function () {
                    return pure(pageStart(v));
                });
            });
        });
    });
});
export {
    mkPageStart
};
