// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Questions from "../Questions/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Icons from "../React.Icons/index.js";
import * as React_Icons_Im from "../React.Icons.Im/index.js";
var icon = /* #__PURE__ */ React_Icons.icon();
var guard = /* #__PURE__ */ Data_Monoid.guard(Data_Monoid.monoidString);
var li = /* #__PURE__ */ React_Basic_DOM_Generated.li();
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Questions.eqOption));
var ul = /* #__PURE__ */ React_Basic_DOM_Generated.ul();
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var pure = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var StepSuccess = /* #__PURE__ */ (function () {
    function StepSuccess() {

    };
    StepSuccess.value = new StepSuccess();
    return StepSuccess;
})();
var StepError = /* #__PURE__ */ (function () {
    function StepError() {

    };
    StepError.value = new StepError();
    return StepError;
})();
var StepCurrent = /* #__PURE__ */ (function () {
    function StepCurrent() {

    };
    StepCurrent.value = new StepCurrent();
    return StepCurrent;
})();
var StepNext = /* #__PURE__ */ (function () {
    function StepNext() {

    };
    StepNext.value = new StepNext();
    return StepNext;
})();
var eqStep = {
    eq: function (x) {
        return function (y) {
            if (x instanceof StepSuccess && y instanceof StepSuccess) {
                return true;
            };
            if (x instanceof StepError && y instanceof StepError) {
                return true;
            };
            if (x instanceof StepCurrent && y instanceof StepCurrent) {
                return true;
            };
            if (x instanceof StepNext && y instanceof StepNext) {
                return true;
            };
            return false;
        };
    }
};
var notEq = /* #__PURE__ */ Data_Eq.notEq(eqStep);
var mkContent = function (v) {
    if (v instanceof StepNext) {
        return icon(React_Icons_Im.imRadioUnchecked)({
            className: "text-neutral"
        });
    };
    if (v instanceof StepCurrent) {
        return icon(React_Icons_Im.imRadioChecked)({
            color: "white",
            className: "text-neutral"
        });
    };
    if (v instanceof StepSuccess) {
        return icon(React_Icons_Im.imCheckmark)({
            className: "text-success"
        });
    };
    if (v instanceof StepError) {
        return icon(React_Icons_Im.imCross)({
            className: "text-error"
        });
    };
    throw new Error("Failed pattern match at Components.AppGameSteps (line 50, column 1 - line 50, column 25): " + [ v.constructor.name ]);
};
var mkStep = function (step) {
    var className = "step gap-2" + guard(notEq(step)(StepNext.value))(" step-neutral");
    return li({
        className: className,
        children: [ mkContent(step) ]
    });
};
var appGameSteps = function (v) {
    var next = map(Data_Function["const"](mkStep(StepNext.value)))(v.inProgressState.nextQuestions);
    var current = (function () {
        if (eq(v.inProgressState.currentAnswer)(new Data_Maybe.Just(v.inProgressState.currentQuestion.correctOption))) {
            return mkStep(StepSuccess.value);
        };
        return mkStep(StepCurrent.value);
    })();
    var answeredStep = function (x) {
        return mkStep((function () {
            var $22 = Questions.isAnswerCorrect(x);
            if ($22) {
                return StepSuccess.value;
            };
            return StepError.value;
        })());
    };
    var answered = map(answeredStep)(Data_Array.reverse(v.inProgressState.answeredQuestions));
    return ul({
        className: Data_String_Common.joinWith(" ")([ "steps max-w-full overflow-hidden font-medium text-sm pt-4 sm:pt-0", (function () {
            var $23 = Data_Array.length(answered) > 2;
            if ($23) {
                return "justify-end";
            };
            return "justify-start";
        })() ]),
        key: "game-steps",
        children: append1(answered)(append1(pure(current))(next))
    });
};
export {
    StepSuccess,
    StepError,
    StepCurrent,
    StepNext,
    appGameSteps,
    mkStep,
    mkContent,
    eqStep
};
