// Generated by purs version 0.15.4
import * as Affjax from "../Affjax/index.js";
import * as Affjax_ResponseFormat from "../Affjax.ResponseFormat/index.js";
import * as Affjax_Web from "../Affjax.Web/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Set_NonEmpty from "../Data.Set.NonEmpty/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
var nameIsSymbol = {
    reflectSymbol: function () {
        return "name";
    }
};
var signatureIsSymbol = {
    reflectSymbol: function () {
        return "signature";
    }
};
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showArray(Data_Show.showString));
var map = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var bimap = /* #__PURE__ */ Data_Bifunctor.bimap(Data_Bifunctor.bifunctorEither);
var Fun = function (x) {
    return x;
};
var showFun = /* #__PURE__ */ Data_Show.showRecord()()(/* #__PURE__ */ Data_Show.showRecordFieldsCons(nameIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(signatureIsSymbol)(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Show.showString));
var ordFun = /* #__PURE__ */ Data_Ord.ordRecord()(/* #__PURE__ */ Data_Ord.ordRecordCons(/* #__PURE__ */ Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(signatureIsSymbol)(Data_Ord.ordString))()(nameIsSymbol)(Data_Ord.ordString));
var newtypeFun_ = {
    Coercible0: function () {
        return undefined;
    }
};
var eqFun = /* #__PURE__ */ Data_Eq.eqRec()(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(signatureIsSymbol)(Data_Eq.eqString))()(nameIsSymbol)(Data_Eq.eqString));
var parseFunctions = /* #__PURE__ */ (function () {
    var toNonEmpty = (function () {
        var $53 = Data_Either.note("Empty");
        var $54 = Data_Set_NonEmpty.fromFoldable(Data_Foldable.foldableArray)(ordFun);
        return function ($55) {
            return $53($54($55));
        };
    })();
    var toLines = (function () {
        var $56 = Data_Array.filter((function () {
            var $59 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
            return function ($60) {
                return $59(Data_String_Common["null"](Data_String_Common.trim($60)));
            };
        })());
        var $57 = Data_String_Common.split("\x0a");
        return function ($58) {
            return $56($57($58));
        };
    })();
    var mkFun = function (v) {
        if (v.length === 2) {
            return new Data_Either.Right({
                name: v[0],
                signature: v[1]
            });
        };
        return new Data_Either.Left("Couldn't parse line: " + show(v));
    };
    var lineToFunction = (function () {
        var $61 = Data_Functor.map(Data_Functor.functorArray)(Data_String_Common.trim);
        var $62 = Data_String_Common.split(" :: ");
        return function ($63) {
            return $61($62($63));
        };
    })();
    return Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(toNonEmpty)((function () {
        var $64 = Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Either.applicativeEither)(function ($66) {
            return mkFun(lineToFunction($66));
        });
        return function ($65) {
            return $64(toLines($65));
        };
    })());
})();
var loadFunctions = function (url) {
    return map(bimap(Affjax.printError)(function (v) {
        return v.body;
    }))(Affjax_Web.get(Affjax_ResponseFormat.string)(url));
};
export {
    loadFunctions,
    parseFunctions,
    Fun,
    showFun,
    eqFun,
    ordFun,
    newtypeFun_
};
