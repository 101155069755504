// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Affjax from "../Affjax/index.js";
var request = /* #__PURE__ */ Affjax.request($foreign.driver);
var put_ = /* #__PURE__ */ Affjax.put_($foreign.driver);
var put = /* #__PURE__ */ Affjax.put($foreign.driver);
var post_ = /* #__PURE__ */ Affjax.post_($foreign.driver);
var post = /* #__PURE__ */ Affjax.post($foreign.driver);
var patch_ = /* #__PURE__ */ Affjax.patch_($foreign.driver);
var patch = /* #__PURE__ */ Affjax.patch($foreign.driver);
var get = /* #__PURE__ */ Affjax.get($foreign.driver);
var delete_ = /* #__PURE__ */ Affjax.delete_($foreign.driver);
var $$delete = /* #__PURE__ */ Affjax["delete"]($foreign.driver);
export {
    driver
} from "./foreign.js";
export {
    request,
    get,
    post,
    post_,
    put,
    put_,
    $$delete as delete,
    delete_,
    patch,
    patch_
};
export {
    RequestContentError,
    RequestFailedError,
    ResponseBodyError,
    TimeoutError,
    XHROtherError,
    defaultRequest,
    printError
} from "../Affjax/index.js";
