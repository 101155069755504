// Generated by purs version 0.15.4
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Bounded from "../Data.Bounded/index.js";
import * as Data_Bounded_Generic from "../Data.Bounded.Generic/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Enum_Generic from "../Data.Enum.Generic/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as React_Icons_Gi from "../React.Icons.Gi/index.js";
import * as React_Icons_Si from "../React.Icons.Si/index.js";
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var genericEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericEnumConstructor(Data_Enum_Generic.genericEnumNoArguments);
var genericTopConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericTopConstructor(Data_Bounded_Generic.genericTopNoArguments);
var genericEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericEnumSum(genericEnumConstructor)(genericTopConstructor);
var genericBottomConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericBottomConstructor(Data_Bounded_Generic.genericBottomNoArguments);
var genericBottomSum = /* #__PURE__ */ Data_Bounded_Generic.genericBottomSum(genericBottomConstructor);
var genericEnumSum1 = /* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(genericEnumConstructor)(genericBottomConstructor))(genericBottomSum))(genericBottomSum);
var genericBoundedEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericBoundedEnumConstructor(Data_Enum_Generic.genericBoundedEnumNoArguments);
var genericBoundedEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericBoundedEnumSum(genericBoundedEnumConstructor);
var genericBoundedEnumSum1 = /* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(genericBoundedEnumConstructor)));
var Haskell = /* #__PURE__ */ (function () {
    function Haskell() {

    };
    Haskell.value = new Haskell();
    return Haskell;
})();
var PureScript = /* #__PURE__ */ (function () {
    function PureScript() {

    };
    PureScript.value = new PureScript();
    return PureScript;
})();
var Elm = /* #__PURE__ */ (function () {
    function Elm() {

    };
    Elm.value = new Elm();
    return Elm;
})();
var HaskellLens = /* #__PURE__ */ (function () {
    function HaskellLens() {

    };
    HaskellLens.value = new HaskellLens();
    return HaskellLens;
})();
var genericLanguage_ = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Haskell.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return PureScript.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return Elm.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return HaskellLens.value;
        };
        throw new Error("Failed pattern match at Languages (line 24, column 1 - line 24, column 35): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Haskell) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof PureScript) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Elm) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof HaskellLens) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at Languages (line 24, column 1 - line 24, column 35): " + [ x.constructor.name ]);
    }
};
var showLanguage = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericLanguage_)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Haskell";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "PureScript";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Elm";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "HaskellLens";
        }
    })))))
};
var eqLanguage = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Haskell && y instanceof Haskell) {
                return true;
            };
            if (x instanceof PureScript && y instanceof PureScript) {
                return true;
            };
            if (x instanceof Elm && y instanceof Elm) {
                return true;
            };
            if (x instanceof HaskellLens && y instanceof HaskellLens) {
                return true;
            };
            return false;
        };
    }
};
var ordLanguage = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Haskell && y instanceof Haskell) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Haskell) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Haskell) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PureScript && y instanceof PureScript) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof PureScript) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof PureScript) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Elm && y instanceof Elm) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Elm) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Elm) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof HaskellLens && y instanceof HaskellLens) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Languages (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqLanguage;
    }
};
var enumLanguage = {
    succ: /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericLanguage_)(genericEnumSum1),
    pred: /* #__PURE__ */ Data_Enum_Generic.genericPred(genericLanguage_)(genericEnumSum1),
    Ord0: function () {
        return ordLanguage;
    }
};
var boundedLanguage = {
    bottom: /* #__PURE__ */ Data_Bounded_Generic.genericBottom(genericLanguage_)(genericBottomSum),
    top: /* #__PURE__ */ Data_Bounded_Generic.genericTop(genericLanguage_)(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(genericTopConstructor)))),
    Ord0: function () {
        return ordLanguage;
    }
};
var boundedEnumLanguage = {
    cardinality: /* #__PURE__ */ Data_Enum_Generic.genericCardinality(genericLanguage_)(genericBoundedEnumSum1),
    toEnum: /* #__PURE__ */ Data_Enum_Generic.genericToEnum(genericLanguage_)(genericBoundedEnumSum1),
    fromEnum: /* #__PURE__ */ Data_Enum_Generic.genericFromEnum(genericLanguage_)(genericBoundedEnumSum1),
    Bounded0: function () {
        return boundedLanguage;
    },
    Enum1: function () {
        return enumLanguage;
    }
};
var languages = /* #__PURE__ */ Data_Enum.enumFromTo(enumLanguage)(Data_Array_NonEmpty_Internal.unfoldable1NonEmptyArray)(/* #__PURE__ */ Data_Bounded.bottom(boundedLanguage))(/* #__PURE__ */ Data_Bounded.top(boundedLanguage));
var languageIcon = function (v) {
    if (v instanceof Haskell) {
        return React_Icons_Si.siHaskell;
    };
    if (v instanceof HaskellLens) {
        return React_Icons_Gi.giMagnifyingGlass;
    };
    if (v instanceof PureScript) {
        return React_Icons_Si.siPurescript;
    };
    if (v instanceof Elm) {
        return React_Icons_Si.siElm;
    };
    throw new Error("Failed pattern match at Languages (line 47, column 1 - line 47, column 38): " + [ v.constructor.name ]);
};
export {
    Haskell,
    PureScript,
    Elm,
    HaskellLens,
    languages,
    languageIcon,
    genericLanguage_,
    eqLanguage,
    showLanguage,
    ordLanguage,
    boundedLanguage,
    boundedEnumLanguage,
    enumLanguage
};
