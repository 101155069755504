// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_DateTime_Instant from "../Data.DateTime.Instant/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Time_Duration from "../Data.Time.Duration/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Now from "../Effect.Now/index.js";
import * as Foreign_Daisyui from "../Foreign.Daisyui/index.js";
import * as Foreign_ReactHotkeysHook from "../Foreign.ReactHotkeysHook/index.js";
import * as Languages from "../Languages/index.js";
import * as Questions from "../Questions/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Events from "../React.Basic.DOM.Events/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Events from "../React.Basic.Events/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Aff from "../React.Basic.Hooks.Aff/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as React_Icons from "../React.Icons/index.js";
import * as React_Icons_Fa from "../React.Icons.Fa/index.js";
import * as React_Icons_Gi from "../React.Icons.Gi/index.js";
import * as React_Icons_Im from "../React.Icons.Im/index.js";
import * as React_Icons_Vsc from "../React.Icons.Vsc/index.js";
import * as Record from "../Record/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var toDuration = /* #__PURE__ */ Data_Time_Duration.toDuration(Data_Time_Duration.durationSeconds);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Time_Duration.semigroupMilliseconds);
var negateDuration = /* #__PURE__ */ Data_Time_Duration.negateDuration(Data_Time_Duration.durationMilliseconds);
var icon = /* #__PURE__ */ React_Icons.icon();
var th = /* #__PURE__ */ React_Basic_DOM_Generated.th();
var td = /* #__PURE__ */ React_Basic_DOM_Generated.td();
var pre = /* #__PURE__ */ React_Basic_DOM_Generated.pre();
var thead = /* #__PURE__ */ React_Basic_DOM_Generated.thead();
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var div = /* #__PURE__ */ React_Basic_DOM_Generated.div();
var table = /* #__PURE__ */ React_Basic_DOM_Generated.table();
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var button_ = /* #__PURE__ */ Foreign_Daisyui.button_();
var show1 = /* #__PURE__ */ Data_Show.show(Languages.showLanguage);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var lessThanOrEq = /* #__PURE__ */ Data_Ord.lessThanOrEq(Data_Time_Duration.ordSeconds);
var span = /* #__PURE__ */ React_Basic_DOM_Generated.span();
var append2 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var guard = /* #__PURE__ */ Data_Monoid.guard(Data_Monoid.monoidArray);
var eq1 = /* #__PURE__ */ Data_Eq.eq(Languages.eqLanguage);
var discard = /* #__PURE__ */ React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender);
var bind = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var useAff = /* #__PURE__ */ React_Basic_Hooks_Aff.useAff(Data_Eq.eqUnit);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var merge = /* #__PURE__ */ Record.merge()();
var toDurationDiff = function (v) {
    return function (v1) {
        if (v1 instanceof Data_Maybe.Just) {
            return new Data_Maybe.Just(toDuration(append(Data_DateTime_Instant.unInstant(v1.value0))(negateDuration(Data_DateTime_Instant.unInstant(v)))));
        };
        if (v1 instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at Components.PageGameEnd (line 215, column 1 - line 215, column 60): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var renderTableRow = function (v) {
    var v1 = Questions.questionFunction(v.value0);
    var answerIcon = (function () {
        var $47 = Questions.isAnswerCorrect(v);
        if ($47) {
            return icon(React_Icons_Im.imCheckmark)({
                className: "text-success"
            });
        };
        return icon(React_Icons_Im.imCross)({
            className: "text-error"
        });
    })();
    return [ th({
        className: "py-0.5 pr-1 sm:pr-3",
        children: [ answerIcon ]
    }), td({
        className: "py-0.5 pr-1 font-bold sm:pr-3",
        children: [ React_Basic_DOM.text(v1.name) ]
    }), td({
        className: "hidden py-0.5 pr-1 sm:table-cell sm:pr-3",
        children: [ pre({
            className: "max-w-lg font-mono font-medium truncate",
            title: v1.signature,
            children: [ React_Basic_DOM.text(v1.signature) ]
        }) ]
    }) ];
};
var renderTable = function (questions) {
    var header = thead({
        className: "hidden sm:table-header-group",
        children: [ React_Basic_DOM_Generated.tr_([ React_Basic_DOM_Generated.th_([  ]), React_Basic_DOM_Generated.th_([ React_Basic_DOM.text("Name") ]), React_Basic_DOM_Generated.th_([ React_Basic_DOM.text("Signature") ]) ]) ]
    });
    var body = React_Basic_DOM_Generated.tbody_(map(function ($75) {
        return React_Basic_DOM_Generated.tr_(renderTableRow($75));
    })(Data_Array.reverse(questions)));
    return div({
        className: "my-6 overflow-x-auto shadow",
        children: [ table({
            className: "table w-full",
            children: [ header, body ]
        }) ]
    });
};
var pageGameEnd = function (v) {
    var v1 = Questions.toStat(v.gameEndState.answeredQuestions);
    var tweet = Data_String_Common.joinWith(" ")([ "Hey there, I scored", show(v1.countCorrect) + ("/" + show(v1.countTotal)), "on https://type-signature.com.", "Give it a shot!" ]);
    var twitterButton = button_({
        color: "info",
        className: "justify-start gap-2",
        onClick: React_Basic_Events.handler(React_Basic_DOM_Events.stopPropagation)(Data_Function["const"]((function () {
            var url = "https://twitter.com/intent/tweet" + ("?via=_andys8" + ("&hashtags=" + show1(v.language) + ("&text=" + tweet)));
            return $$void(bindFlipped(Web_HTML_Window.open(url)("_blank")(""))(Web_HTML.window));
        })())),
        children: [ React_Icons.icon_(React_Icons_Fa.faTwitter), React_Basic_DOM.text("Share on Twitter") ]
    });
    var speedRunBadge = React_Basic.element(Foreign_Daisyui.badge)({
        size: "md",
        responsive: false,
        color: "neutral",
        className: "gap-1",
        children: [ React_Icons.icon_(React_Icons_Gi.giSpeedometer), React_Basic_DOM.text("Speedrun") ]
    });
    var restartButton = button_({
        color: "default",
        onClick: React_Basic_Events.handler_(v.onRestart),
        className: "justify-start gap-2",
        children: [ React_Icons.icon_(React_Icons_Vsc.vscDebugRestart), React_Basic_DOM.text("Try again") ]
    });
    var renderSeconds = function (v2) {
        return show(Data_Int.round(v2)) + " seconds";
    };
    var haskellLensModeButton = button_({
        color: "accent",
        onClick: React_Basic_Events.handler_(v.onHaskellLensMode),
        className: "justify-start gap-2",
        children: [ React_Icons.icon_(Languages.languageIcon(Languages.HaskellLens.value)), React_Basic_DOM.text("Kmett Mode") ]
    });
    var duration = toDurationDiff(v.gameEndState.startTime)(v.currentTime);
    var isPerfectResult = (function () {
        if (duration instanceof Data_Maybe.Just) {
            return lessThanOrEq(duration.value0)(30.0) && v1.score === 1.0;
        };
        return false;
    })();
    var statTitle = (function () {
        if (isPerfectResult) {
            return speedRunBadge;
        };
        return React_Basic_DOM.text("Result");
    })();
    var statText = (function () {
        if (v1.score > 0.8) {
            if (duration instanceof Data_Maybe.Just && lessThanOrEq(duration.value0)(30.0)) {
                return "Crazy, only " + (renderSeconds(duration.value0) + "!");
            };
            if (duration instanceof Data_Maybe.Just && lessThanOrEq(duration.value0)(60.0)) {
                return "Wow, only " + (renderSeconds(duration.value0) + "!");
            };
            return "Impressive";
        };
        if (v1.score > 0.5) {
            return "Well done";
        };
        if (v1.score > 0.0) {
            return "Good start";
        };
        return "Don't give up!";
    })();
    var resultStat = React_Basic.element(Foreign_Daisyui.stats)({
        className: Data_String_Common.joinWith(" ")([ "shadow px-4 select-none animate-fadein", (function () {
            var $61 = v1.score === 1.0;
            if ($61) {
                return "bg-success text-success-content";
            };
            var $62 = v1.score > 0.5;
            if ($62) {
                return "bg-primary text-primary-content";
            };
            return "bg-secondary text-secondary-content";
        })() ]),
        children: [ React_Basic.element(Foreign_Daisyui.stat)({
            children: [ React_Basic.element(Foreign_Daisyui.statItem)({
                variant: "title",
                children: [ statTitle ]
            }), React_Basic.element(Foreign_Daisyui.statItem)({
                variant: "value",
                children: [ span({
                    className: "font-bold text-9xl",
                    children: [ React_Basic_DOM.text(show(v1.countCorrect)) ]
                }), span({
                    className: "text-5xl",
                    children: [ React_Basic_DOM.text("/") ]
                }), span({
                    className: "text-3xl",
                    children: [ React_Basic_DOM.text(show(v1.countTotal)) ]
                }) ]
            }), React_Basic.element(Foreign_Daisyui.statItem)({
                variant: "desc",
                children: [ React_Basic_DOM.text(statText) ]
            }), icon(React_Icons_Gi.giRibbonMedal)({
                size: "120px",
                color: "white",
                className: "absolute -mt-[30px] -ml-[86px] z-50"
            }) ]
        }) ]
    });
    var buttons = div({
        className: "flex flex-col sm:flex-row gap-4",
        children: append2(Data_Array.cons(restartButton)(guard(isPerfectResult && eq1(v.language)(Languages.Haskell.value))([ haskellLensModeButton ])))(guard(v1.score > 0.5)([ twitterButton ]))
    });
    return React_Basic.fragment([ resultStat, renderTable(v.gameEndState.answeredQuestions), buttons, icon(Languages.languageIcon(v.language))({
        size: "32px",
        className: "mt-6 text-base-300"
    }) ]);
};
var mkPageGameEnd = /* #__PURE__ */ React_Basic_Hooks.component("PageGameEnd")(function (v) {
    return discard(Foreign_ReactHotkeysHook.useHotkeys("space, return, t")(v.onRestart))(function () {
        return bind(useAff(Data_Unit.unit)(liftEffect(Effect_Now.now)))(function (currentTime) {
            return pure(pageGameEnd(merge(v)({
                currentTime: currentTime
            })));
        });
    });
});
export {
    mkPageGameEnd
};
