// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_DateTime_Instant from "../Data.DateTime.Instant/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Class_Console from "../Effect.Class.Console/index.js";
import * as Effect_Now from "../Effect.Now/index.js";
import * as Foreign_Confetti from "../Foreign.Confetti/index.js";
import * as Functions from "../Functions/index.js";
import * as Languages from "../Languages/index.js";
import * as Questions from "../Questions/index.js";
import * as React_Basic_Hooks_Aff from "../React.Basic.Hooks.Aff/index.js";
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var showRecordFieldsCons = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "answeredQuestions";
    }
});
var showRecordFieldsCons1 = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "startTime";
    }
})(Data_Show.showRecordFieldsNil)(Data_DateTime_Instant.showInstant);
var correctOptionIsSymbol = {
    reflectSymbol: function () {
        return "correctOption";
    }
};
var optionAIsSymbol = {
    reflectSymbol: function () {
        return "optionA";
    }
};
var optionBIsSymbol = {
    reflectSymbol: function () {
        return "optionB";
    }
};
var optionCIsSymbol = {
    reflectSymbol: function () {
        return "optionC";
    }
};
var optionDIsSymbol = {
    reflectSymbol: function () {
        return "optionD";
    }
};
var showRecord1 = /* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons(correctOptionIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(optionAIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(optionBIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(optionCIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(optionDIsSymbol)(Data_Show.showRecordFieldsNil)(Functions.showFun))(Functions.showFun))(Functions.showFun))(Functions.showFun))(Questions.showOption));
var showArray = /* #__PURE__ */ Data_Show.showArray(Questions.showAnsweredQuestion);
var eq = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(Questions.eqAnsweredQuestion));
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(Questions.eqOption));
var eq2 = /* #__PURE__ */ Data_Eq.eq(Questions.eqOption);
var eq3 = /* #__PURE__ */ Data_Eq.eq(Functions.eqFun);
var eq4 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(/* #__PURE__ */ Data_Eq.eqRec()(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(optionDIsSymbol)(Functions.eqFun))()(optionCIsSymbol)(Functions.eqFun))()(optionBIsSymbol)(Functions.eqFun))()(optionAIsSymbol)(Functions.eqFun))()(correctOptionIsSymbol)(Questions.eqOption))));
var eq5 = /* #__PURE__ */ Data_Eq.eq(Data_DateTime_Instant.eqDateTime);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var mkQuestions = /* #__PURE__ */ Questions.mkQuestions(Effect_Class.monadEffectEffect);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Effect.applyEffect);
var error = /* #__PURE__ */ Effect_Class_Console.error(Effect_Class.monadEffectEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff);
var when = /* #__PURE__ */ Control_Applicative.when(Effect_Aff.applicativeAff);
var GameBeforeStart = /* #__PURE__ */ (function () {
    function GameBeforeStart() {

    };
    GameBeforeStart.value = new GameBeforeStart();
    return GameBeforeStart;
})();
var GameInProgress = /* #__PURE__ */ (function () {
    function GameInProgress(value0) {
        this.value0 = value0;
    };
    GameInProgress.create = function (value0) {
        return new GameInProgress(value0);
    };
    return GameInProgress;
})();
var GameEnd = /* #__PURE__ */ (function () {
    function GameEnd(value0) {
        this.value0 = value0;
    };
    GameEnd.create = function (value0) {
        return new GameEnd(value0);
    };
    return GameEnd;
})();
var ActionGameStart = /* #__PURE__ */ (function () {
    function ActionGameStart() {

    };
    ActionGameStart.value = new ActionGameStart();
    return ActionGameStart;
})();
var ActionLanguageSet = /* #__PURE__ */ (function () {
    function ActionLanguageSet(value0) {
        this.value0 = value0;
    };
    ActionLanguageSet.create = function (value0) {
        return new ActionLanguageSet(value0);
    };
    return ActionLanguageSet;
})();
var ActionInitHaskellLensMode = /* #__PURE__ */ (function () {
    function ActionInitHaskellLensMode() {

    };
    ActionInitHaskellLensMode.value = new ActionInitHaskellLensMode();
    return ActionInitHaskellLensMode;
})();
var ActionNewGame = /* #__PURE__ */ (function () {
    function ActionNewGame(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ActionNewGame.create = function (value0) {
        return function (value1) {
            return new ActionNewGame(value0, value1);
        };
    };
    return ActionNewGame;
})();
var ActionAnswer = /* #__PURE__ */ (function () {
    function ActionAnswer(value0) {
        this.value0 = value0;
    };
    ActionAnswer.create = function (value0) {
        return new ActionAnswer(value0);
    };
    return ActionAnswer;
})();
var ActionNextQuestion = /* #__PURE__ */ (function () {
    function ActionNextQuestion() {

    };
    ActionNextQuestion.value = new ActionNextQuestion();
    return ActionNextQuestion;
})();
var genericGameState_ = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return GameBeforeStart.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new GameInProgress(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new GameEnd(x.value0.value0);
        };
        throw new Error("Failed pattern match at State (line 38, column 1 - line 38, column 36): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof GameBeforeStart) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof GameInProgress) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof GameEnd) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at State (line 38, column 1 - line 38, column 36): " + [ x.constructor.name ]);
    }
};
var showGameState = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericGameState_)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "GameBeforeStart";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "currentAnswer";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "currentQuestion";
        }
    })(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
        reflectSymbol: function () {
            return "nextQuestions";
        }
    })(showRecordFieldsCons1)(/* #__PURE__ */ Data_Show.showArray(showRecord1)))(showRecord1))(/* #__PURE__ */ Data_Maybe.showMaybe(Questions.showOption)))(showArray))))({
        reflectSymbol: function () {
            return "GameInProgress";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(showRecordFieldsCons1)(showArray))))({
        reflectSymbol: function () {
            return "GameEnd";
        }
    }))))
};
var eqGameState = {
    eq: function (x) {
        return function (y) {
            if (x instanceof GameBeforeStart && y instanceof GameBeforeStart) {
                return true;
            };
            if (x instanceof GameInProgress && y instanceof GameInProgress) {
                return eq(x.value0.answeredQuestions)(y.value0.answeredQuestions) && eq1(x.value0.currentAnswer)(y.value0.currentAnswer) && (eq2(x.value0.currentQuestion.correctOption)(y.value0.currentQuestion.correctOption) && eq3(x.value0.currentQuestion.optionA)(y.value0.currentQuestion.optionA) && eq3(x.value0.currentQuestion.optionB)(y.value0.currentQuestion.optionB) && eq3(x.value0.currentQuestion.optionC)(y.value0.currentQuestion.optionC) && eq3(x.value0.currentQuestion.optionD)(y.value0.currentQuestion.optionD)) && eq4(x.value0.nextQuestions)(y.value0.nextQuestions) && eq5(x.value0.startTime)(y.value0.startTime);
            };
            if (x instanceof GameEnd && y instanceof GameEnd) {
                return eq(x.value0.answeredQuestions)(y.value0.answeredQuestions) && eq5(x.value0.startTime)(y.value0.startTime);
            };
            return false;
        };
    }
};
var toFunctions = function (v) {
    if (v.language instanceof Languages.Haskell) {
        return v.functions.haskell;
    };
    if (v.language instanceof Languages.HaskellLens) {
        return v.functions.haskellLens;
    };
    if (v.language instanceof Languages.PureScript) {
        return v.functions.purescript;
    };
    if (v.language instanceof Languages.Elm) {
        return v.functions.elm;
    };
    throw new Error("Failed pattern match at State (line 162, column 39 - line 166, column 23): " + [ v.language.constructor.name ]);
};
var isGameInTransition = function (v) {
    if (v.gameState instanceof GameInProgress) {
        return Data_Maybe.isJust(v.gameState.value0.currentAnswer);
    };
    return false;
};
var reducer = function (v) {
    return function (v1) {
        if (v1 instanceof ActionGameStart) {
            var newGame = liftEffect(function __do() {
                var currentTime = Effect_Now.now();
                var questions = mkQuestions(6)(toFunctions(v))();
                if (questions instanceof Data_Either.Left) {
                    return applySecond(error(questions.value0))(pure([  ]))();
                };
                if (questions instanceof Data_Either.Right) {
                    return [ new ActionNewGame(questions.value0, currentTime) ];
                };
                throw new Error("Failed pattern match at State (line 79, column 5 - line 81, column 56): " + [ questions.constructor.name ]);
            });
            return {
                state: v,
                effects: [ newGame ]
            };
        };
        if (v1 instanceof ActionLanguageSet) {
            return React_Basic_Hooks_Aff.noEffects({
                language: v1.value0,
                functions: v.functions,
                gameState: v.gameState
            });
        };
        if (v1 instanceof ActionInitHaskellLensMode) {
            return {
                state: {
                    functions: v.functions,
                    language: Languages.HaskellLens.value,
                    gameState: v.gameState
                },
                effects: [ pure1([ ActionGameStart.value ]) ]
            };
        };
        if (v1 instanceof ActionNewGame) {
            var v2 = Data_Array_NonEmpty.uncons(v1.value0);
            var gameInProgressState = {
                answeredQuestions: [  ],
                currentQuestion: v2.head,
                currentAnswer: Data_Maybe.Nothing.value,
                nextQuestions: v2.tail,
                startTime: v1.value1
            };
            return React_Basic_Hooks_Aff.noEffects({
                gameState: new GameInProgress(gameInProgressState),
                functions: v.functions,
                language: v.language
            });
        };
        if (v1 instanceof ActionAnswer && isGameInTransition(v)) {
            return React_Basic_Hooks_Aff.noEffects(v);
        };
        if (v1 instanceof ActionAnswer) {
            var newGameState = (function () {
                if (v.gameState instanceof GameInProgress) {
                    return new GameInProgress({
                        answeredQuestions: v.gameState.value0.answeredQuestions,
                        currentQuestion: v.gameState.value0.currentQuestion,
                        currentAnswer: new Data_Maybe.Just(v1.value0),
                        nextQuestions: v.gameState.value0.nextQuestions,
                        startTime: v.gameState.value0.startTime
                    });
                };
                if (v.gameState instanceof GameBeforeStart) {
                    return v.gameState;
                };
                if (v.gameState instanceof GameEnd) {
                    return v.gameState;
                };
                throw new Error("Failed pattern match at State (line 120, column 5 - line 123, column 29): " + [ v.gameState.constructor.name ]);
            })();
            var isAnswerCorrect = (function () {
                if (v.gameState instanceof GameInProgress) {
                    return eq2(v.gameState.value0.currentQuestion.correctOption)(v1.value0);
                };
                return false;
            })();
            return {
                state: {
                    functions: v.functions,
                    language: v.language,
                    gameState: newGameState
                },
                effects: [ discard(when(isAnswerCorrect)(liftEffect(Foreign_Confetti.confetti)))(function () {
                    return discard(Effect_Aff.delay(1500.0))(function () {
                        return pure1([ ActionNextQuestion.value ]);
                    });
                }) ]
            };
        };
        if (v1 instanceof ActionNextQuestion) {
            var nextQuestion = function (v2) {
                if (v2.currentAnswer instanceof Data_Maybe.Just) {
                    var answeredQuestion = new Questions.AnsweredQuestion(v2.currentQuestion, v2.currentAnswer.value0);
                    var answeredQuestions = Data_Array.cons(answeredQuestion)(v2.answeredQuestions);
                    var v3 = Data_Array.uncons(v2.nextQuestions);
                    if (v3 instanceof Data_Maybe.Nothing) {
                        return new GameEnd({
                            startTime: v2.startTime,
                            answeredQuestions: answeredQuestions
                        });
                    };
                    if (v3 instanceof Data_Maybe.Just) {
                        return new GameInProgress({
                            answeredQuestions: answeredQuestions,
                            currentQuestion: v3.value0.head,
                            currentAnswer: Data_Maybe.Nothing.value,
                            nextQuestions: v3.value0.tail,
                            startTime: v2.startTime
                        });
                    };
                    throw new Error("Failed pattern match at State (line 149, column 11 - line 158, column 18): " + [ v3.constructor.name ]);
                };
                if (v2.currentAnswer instanceof Data_Maybe.Nothing) {
                    return new GameInProgress(v2);
                };
                throw new Error("Failed pattern match at State (line 143, column 5 - line 159, column 34): " + [ v2.currentAnswer.constructor.name ]);
            };
            var newGameState = (function () {
                if (v.gameState instanceof GameInProgress) {
                    return nextQuestion(v.gameState.value0);
                };
                return v.gameState;
            })();
            var hasWinAnimationThreshold = function (v2) {
                return (Questions.toStat(v2.answeredQuestions)).score > 0.8;
            };
            var winAnimation = (function () {
                if (newGameState instanceof GameEnd && hasWinAnimationThreshold(newGameState.value0)) {
                    return applySecond(Foreign_Confetti.schoolPride)(pure([  ]));
                };
                return pure([  ]);
            })();
            return {
                state: {
                    functions: v.functions,
                    language: v.language,
                    gameState: newGameState
                },
                effects: [ liftEffect(winAnimation) ]
            };
        };
        throw new Error("Failed pattern match at State (line 71, column 1 - line 71, column 88): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var initState = function (functions) {
    return {
        functions: functions,
        language: Languages.Haskell.value,
        gameState: GameBeforeStart.value
    };
};
export {
    GameBeforeStart,
    GameInProgress,
    GameEnd,
    ActionGameStart,
    ActionLanguageSet,
    ActionInitHaskellLensMode,
    ActionNewGame,
    ActionAnswer,
    ActionNextQuestion,
    initState,
    reducer,
    toFunctions,
    isGameInTransition,
    genericGameState_,
    eqGameState,
    showGameState
};
