// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
var useHotkeys = function (key) {
    return function (handler) {
        return React_Basic_Hooks_Internal.unsafeHook(function () {
            return $foreign["_useHotkeys"](key, handler);
        });
    };
};
export {
    useHotkeys
};
