// Generated by purs version 0.15.4
import * as Components_AppFooter from "../Components.AppFooter/index.js";
import * as Components_AppNavbar from "../Components.AppNavbar/index.js";
import * as Components_PageGameEnd from "../Components.PageGameEnd/index.js";
import * as Components_PageGameInProgress from "../Components.PageGameInProgress/index.js";
import * as Components_PageStart from "../Components.PageStart/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Parallel from "../Control.Parallel/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Foreign_Daisyui from "../Foreign.Daisyui/index.js";
import * as Functions from "../Functions/index.js";
import * as FunctionsRaw from "../FunctionsRaw/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Client from "../React.Basic.DOM.Client/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Aff from "../React.Basic.Hooks.Aff/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as State from "../State/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
import * as Web_DOM_NonElementParentNode from "../Web.DOM.NonElementParentNode/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_HTMLDocument from "../Web.HTML.HTMLDocument/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var bind1 = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Either.bindEither);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Either.applicativeEither);
var useAff = /* #__PURE__ */ React_Basic_Hooks_Aff.useAff(Data_Eq.eqUnit);
var main1 = /* #__PURE__ */ React_Basic_DOM_Generated.main();
var div = /* #__PURE__ */ React_Basic_DOM_Generated.div();
var mkGame = function __do() {
    var r = React_Basic_Hooks_Aff.mkAffReducer(State.reducer)();
    var pageStart = Components_PageStart.mkPageStart();
    var pageGameInProgress = Components_PageGameInProgress.mkPageGameInProgress();
    var pageGameEnd = Components_PageGameEnd.mkPageGameEnd();
    return React_Basic_Hooks.component("App")(function (v) {
        return bind1(React_Basic_Hooks_Aff.useAffReducer(State.initState(v.functions))(r))(function (v1) {
            return pure((function () {
                if (v1.value0.gameState instanceof State.GameBeforeStart) {
                    return pageStart({
                        onStartClick: v1.value1(State.ActionGameStart.value),
                        onLanguageSet: function ($43) {
                            return v1.value1(State.ActionLanguageSet.create($43));
                        },
                        language: v1.value0.language
                    });
                };
                if (v1.value0.gameState instanceof State.GameInProgress) {
                    return pageGameInProgress({
                        onAnswerClick: function ($44) {
                            return v1.value1(State.ActionAnswer.create($44));
                        },
                        inProgressState: v1.value0.gameState.value0,
                        language: v1.value0.language
                    });
                };
                if (v1.value0.gameState instanceof State.GameEnd) {
                    return pageGameEnd({
                        onRestart: v1.value1(State.ActionGameStart.value),
                        onHaskellLensMode: v1.value1(State.ActionInitHaskellLensMode.value),
                        gameEndState: v1.value0.gameState.value0,
                        language: v1.value0.language
                    });
                };
                throw new Error("Failed pattern match at Main (line 79, column 10 - line 98, column 12): " + [ v1.value0.gameState.constructor.name ]);
            })());
        });
    })();
};
var loadAllFunctions = /* #__PURE__ */ (function () {
    var toAllFunctions = function (v) {
        if (v.length === 4) {
            return new Data_Either.Right({
                haskell: v[0],
                haskellLens: v[1],
                purescript: v[2],
                elm: v[3]
            });
        };
        return new Data_Either.Left("Can't create AllFunctions");
    };
    return Control_Bind.bind(Effect_Aff.bindAff)(Control_Parallel.parSequence(Effect_Aff.parallelAff)(Data_Traversable.traversableArray)(Data_Functor.map(Data_Functor.functorArray)(Functions.loadFunctions)([ FunctionsRaw.urls.haskell, FunctionsRaw.urls.haskellLens, FunctionsRaw.urls.purescript, FunctionsRaw.urls.elm ])))(function (functions) {
        return pure1(bindFlipped(toAllFunctions)(traverse(bindFlipped(Functions.parseFunctions))(functions)));
    });
})();
var mkApp = function __do() {
    var game = mkGame();
    return React_Basic_Hooks.component("App")(function (v) {
        return bind1(useAff(Data_Unit.unit)(loadAllFunctions))(function (response) {
            var content = (function () {
                if (response instanceof Data_Maybe.Nothing) {
                    return React_Basic.element(Foreign_Daisyui.progress)({
                        className: "w-56"
                    });
                };
                if (response instanceof Data_Maybe.Just) {
                    if (response.value0 instanceof Data_Either.Left) {
                        return React_Basic.element(Foreign_Daisyui.alert)({
                            status: "error",
                            children: [ React_Basic_DOM.text(response.value0.value0) ]
                        });
                    };
                    if (response.value0 instanceof Data_Either.Right) {
                        return game({
                            functions: response.value0.value0
                        });
                    };
                    throw new Error("Failed pattern match at Main (line 52, column 19 - line 54, column 48): " + [ response.value0.constructor.name ]);
                };
                throw new Error("Failed pattern match at Main (line 50, column 17 - line 54, column 48): " + [ response.constructor.name ]);
            })();
            var appContent = main1({
                className: "flex flex-col items-center justify-center flex-1 p-5 text-center sm:bg-logo-alt",
                children: [ content ]
            });
            return pure(div({
                className: "absolute inset-0 flex flex-col justify-between font-sans",
                children: [ Components_AppNavbar.appNavbar, appContent, Components_AppFooter.appFooter ]
            }));
        });
    })();
};
var main = function __do() {
    var doc = Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window)();
    var root = Web_DOM_NonElementParentNode.getElementById("root")(Web_HTML_HTMLDocument.toNonElementParentNode(doc))();
    if (root instanceof Data_Maybe.Nothing) {
        return Effect_Exception["throw"]("Could not find container element")();
    };
    if (root instanceof Data_Maybe.Just) {
        var app = mkApp();
        var reactRoot = React_Basic_DOM_Client.createRoot(root.value0)();
        return React_Basic_DOM_Client.renderRoot(reactRoot)(app({}))();
    };
    throw new Error("Failed pattern match at Main (line 37, column 3 - line 42, column 36): " + [ root.constructor.name ]);
};
export {
    main,
    mkApp,
    mkGame,
    loadAllFunctions
};
