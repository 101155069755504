// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as JSURI from "../JSURI/index.js";
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_Show.showArray(/* #__PURE__ */ Data_Tuple.showTuple(Data_Show.showString)(/* #__PURE__ */ Data_Maybe.showMaybe(Data_Show.showString))));
var apply = /* #__PURE__ */ Control_Apply.apply(Data_Maybe.applyMaybe);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var FormURLEncoded = function (x) {
    return x;
};
var toArray = function (v) {
    return v;
};
var showFormUrlEncoded = {
    show: function (v) {
        return "(FormURLEncoded " + (show(v) + ")");
    }
};
var semigroupFormUrlEncoded = Data_Semigroup.semigroupArray;
var ordFormUrlEncoded = /* #__PURE__ */ Data_Ord.ordArray(/* #__PURE__ */ Data_Tuple.ordTuple(Data_Ord.ordString)(/* #__PURE__ */ Data_Maybe.ordMaybe(Data_Ord.ordString)));
var newtypeFormUrlEncoded = {
    Coercible0: function () {
        return undefined;
    }
};
var monoidFormUrlEncoded = Data_Monoid.monoidArray;
var fromArray = FormURLEncoded;
var eqFormUrlEncoded = /* #__PURE__ */ Data_Eq.eqArray(/* #__PURE__ */ Data_Tuple.eqTuple(Data_Eq.eqString)(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqString)));
var encode = /* #__PURE__ */ (function () {
    var encodePart = function (v) {
        if (v.value1 instanceof Data_Maybe.Nothing) {
            return JSURI.encodeFormURLComponent(v.value0);
        };
        if (v.value1 instanceof Data_Maybe.Just) {
            return apply(map(function (key) {
                return function (val) {
                    return key + ("=" + val);
                };
            })(JSURI.encodeFormURLComponent(v.value0)))(JSURI.encodeFormURLComponent(v.value1.value0));
        };
        throw new Error("Failed pattern match at Data.FormURLEncoded (line 37, column 16 - line 39, column 114): " + [ v.constructor.name ]);
    };
    var $37 = map(Data_String_Common.joinWith("&"));
    var $38 = traverse(encodePart);
    return function ($39) {
        return $37($38(toArray($39)));
    };
})();
var decode = /* #__PURE__ */ (function () {
    var decodePart = (function () {
        var $40 = Data_String_Common.split("=");
        return function ($41) {
            return (function (v) {
                if (v.length === 2) {
                    return apply(map(function (key) {
                        return function (val) {
                            return new Data_Tuple.Tuple(key, new Data_Maybe.Just(val));
                        };
                    })(JSURI.decodeFormURLComponent(v[0])))(JSURI.decodeFormURLComponent(v[1]));
                };
                if (v.length === 1) {
                    return apply(map(Data_Tuple.Tuple.create)(JSURI.decodeFormURLComponent(v[0])))(pure(Data_Maybe.Nothing.value));
                };
                return Data_Maybe.Nothing.value;
            })($40($41));
        };
    })();
    var $42 = map(FormURLEncoded);
    var $43 = traverse(decodePart);
    var $44 = Data_String_Common.split("&");
    return function ($45) {
        return $42($43($44($45)));
    };
})();
export {
    FormURLEncoded,
    fromArray,
    toArray,
    encode,
    decode,
    newtypeFormUrlEncoded,
    eqFormUrlEncoded,
    ordFormUrlEncoded,
    semigroupFormUrlEncoded,
    monoidFormUrlEncoded,
    showFormUrlEncoded
};
