// Generated by purs version 0.15.4
import * as Foreign_Daisyui from "../Foreign.Daisyui/index.js";
import * as Foreign_Logo from "../Foreign.Logo/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
var appNavbar = /* #__PURE__ */ React_Basic.element(Foreign_Daisyui.navbar)({
    className: "z-50 shadow-md bg-neutral",
    children: [ /* #__PURE__ */ React_Basic.element(Foreign_Daisyui.navbarStart)({
        className: "flex flex-row items-center justify-between mx-3",
        children: [ /* #__PURE__ */ React_Basic_DOM_Generated.a()({
            href: "/",
            children: [ /* #__PURE__ */ React_Basic_DOM_Generated.span()({
                className: "text-xl font-semibold whitespace-nowrap text-base-content",
                children: [ /* #__PURE__ */ React_Basic_DOM.text("type-signature.com") ]
            }) ]
        }), /* #__PURE__ */ React_Basic_DOM_Generated.img()({
            src: Foreign_Logo.logoSmall,
            height: "32px",
            width: "32px"
        }) ]
    }) ]
});
export {
    appNavbar
};
