// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Basic_DOM_Internal from "../React.Basic.DOM.Internal/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var text = Unsafe_Coerce.unsafeCoerce;
var render$prime = function () {
    return $foreign.renderThen;
};
var render$prime1 = /* #__PURE__ */ render$prime();
var render = function () {
    return function (jsx) {
        return function (node) {
            return render$prime1(jsx)(node)(pure(Data_Unit.unit));
        };
    };
};
var hydrate$prime = function () {
    return $foreign.hydrateThen;
};
var hydrate$prime1 = /* #__PURE__ */ hydrate$prime();
var hydrate = function () {
    return function (jsx) {
        return function (node) {
            return hydrate$prime1(jsx)(node)(pure(Data_Unit.unit));
        };
    };
};
export {
    unmount,
    createPortal
} from "./foreign.js";
export {
    render,
    render$prime,
    hydrate,
    hydrate$prime,
    text
};
export {
    a,
    a$prime,
    a_,
    abbr,
    abbr$prime,
    abbr_,
    address,
    address$prime,
    address_,
    area,
    area$prime,
    article,
    article$prime,
    article_,
    aside,
    aside$prime,
    aside_,
    audio,
    audio$prime,
    audio_,
    b,
    b$prime,
    b_,
    base,
    base$prime,
    bdi,
    bdi$prime,
    bdi_,
    bdo,
    bdo$prime,
    bdo_,
    blockquote,
    blockquote$prime,
    blockquote_,
    body,
    body$prime,
    body_,
    br,
    br$prime,
    button,
    button$prime,
    button_,
    canvas,
    canvas$prime,
    canvas_,
    caption,
    caption$prime,
    caption_,
    cite,
    cite$prime,
    cite_,
    code,
    code$prime,
    code_,
    col,
    col$prime,
    colgroup,
    colgroup$prime,
    colgroup_,
    data$prime,
    data$prime$prime,
    data_,
    datalist,
    datalist$prime,
    datalist_,
    dd,
    dd$prime,
    dd_,
    del,
    del$prime,
    del_,
    details,
    details$prime,
    details_,
    dfn,
    dfn$prime,
    dfn_,
    dialog,
    dialog$prime,
    dialog_,
    div,
    div$prime,
    div_,
    dl,
    dl$prime,
    dl_,
    dt,
    dt$prime,
    dt_,
    em,
    em$prime,
    em_,
    embed,
    embed$prime,
    fieldset,
    fieldset$prime,
    fieldset_,
    figcaption,
    figcaption$prime,
    figcaption_,
    figure,
    figure$prime,
    figure_,
    footer,
    footer$prime,
    footer_,
    form,
    form$prime,
    form_,
    h1,
    h1$prime,
    h1_,
    h2,
    h2$prime,
    h2_,
    h3,
    h3$prime,
    h3_,
    h4,
    h4$prime,
    h4_,
    h5,
    h5$prime,
    h5_,
    h6,
    h6$prime,
    h6_,
    head,
    head$prime,
    head_,
    header,
    header$prime,
    header_,
    hgroup,
    hgroup$prime,
    hgroup_,
    hr,
    hr$prime,
    html,
    html$prime,
    html_,
    i,
    i$prime,
    i_,
    iframe,
    iframe$prime,
    iframe_,
    img,
    img$prime,
    input,
    input$prime,
    ins,
    ins$prime,
    ins_,
    kbd,
    kbd$prime,
    kbd_,
    keygen,
    keygen$prime,
    keygen_,
    label,
    label$prime,
    label_,
    legend,
    legend$prime,
    legend_,
    li,
    li$prime,
    li_,
    link,
    link$prime,
    main,
    main$prime,
    main_,
    map,
    map$prime,
    map_,
    mark,
    mark$prime,
    mark_,
    math,
    math$prime,
    math_,
    menu,
    menu$prime,
    menu_,
    menuitem,
    menuitem$prime,
    menuitem_,
    meta,
    meta$prime,
    meter,
    meter$prime,
    meter_,
    nav,
    nav$prime,
    nav_,
    noscript,
    noscript$prime,
    noscript_,
    object,
    object$prime,
    object_,
    ol,
    ol$prime,
    ol_,
    optgroup,
    optgroup$prime,
    optgroup_,
    option,
    option$prime,
    option_,
    output,
    output$prime,
    output_,
    p,
    p$prime,
    p_,
    param,
    param$prime,
    picture,
    picture$prime,
    picture_,
    pre,
    pre$prime,
    pre_,
    progress,
    progress$prime,
    progress_,
    q,
    q$prime,
    q_,
    rb,
    rb$prime,
    rb_,
    rp,
    rp$prime,
    rp_,
    rt,
    rt$prime,
    rt_,
    rtc,
    rtc$prime,
    rtc_,
    ruby,
    ruby$prime,
    ruby_,
    s,
    s$prime,
    s_,
    samp,
    samp$prime,
    samp_,
    script,
    script$prime,
    script_,
    section,
    section$prime,
    section_,
    select,
    select$prime,
    select_,
    slot,
    slot$prime,
    slot_,
    small,
    small$prime,
    small_,
    source,
    source$prime,
    span,
    span$prime,
    span_,
    strong,
    strong$prime,
    strong_,
    style,
    style$prime,
    style_,
    sub,
    sub$prime,
    sub_,
    summary,
    summary$prime,
    summary_,
    sup,
    sup$prime,
    sup_,
    table,
    table$prime,
    table_,
    tbody,
    tbody$prime,
    tbody_,
    td,
    td$prime,
    td_,
    template,
    template$prime,
    template_,
    textarea,
    textarea$prime,
    textarea_,
    tfoot,
    tfoot$prime,
    tfoot_,
    th,
    th$prime,
    th_,
    thead,
    thead$prime,
    thead_,
    time,
    time$prime,
    time_,
    title,
    title$prime,
    title_,
    tr,
    tr$prime,
    tr_,
    track,
    track$prime,
    u,
    u$prime,
    u_,
    ul,
    ul$prime,
    ul_,
    var,
    var$prime,
    var_,
    video,
    video$prime,
    video_,
    wbr,
    wbr$prime
} from "../React.Basic.DOM.Generated/index.js";
export {
    css,
    mergeStyles,
    unsafeCreateDOMComponent
} from "../React.Basic.DOM.Internal/index.js";
