// Generated by purs version 0.15.4
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Lazy from "../Control.Lazy/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_List_Internal from "../Data.List.Internal/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_NonEmpty from "../Data.NonEmpty/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var tailRecM2 = /* #__PURE__ */ Control_Monad_Rec_Class.tailRecM2(Control_Monad_Rec_Class.monadRecMaybe);
var eq = /* #__PURE__ */ Data_Eq.eq(Data_Ordering.eqOrdering);
var notEq = /* #__PURE__ */ Data_Eq.notEq(Data_Ordering.eqOrdering);
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Data_List_Types.traversableList);
var bimap = /* #__PURE__ */ Data_Bifunctor.bimap(Control_Monad_Rec_Class.bifunctorStep);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_List_Types.foldableList);
var any = /* #__PURE__ */ Data_Foldable.any(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_List_Types.semigroupList);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_List_Types.bindList);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var Pattern = function (x) {
    return x;
};
var updateAt = function (v) {
    return function (v1) {
        return function (v2) {
            if (v === 0 && v2 instanceof Data_List_Types.Cons) {
                return new Data_Maybe.Just(new Data_List_Types.Cons(v1, v2.value1));
            };
            if (v2 instanceof Data_List_Types.Cons) {
                return map(function (v3) {
                    return new Data_List_Types.Cons(v2.value0, v3);
                })(updateAt(v - 1 | 0)(v1)(v2.value1));
            };
            return Data_Maybe.Nothing.value;
        };
    };
};
var unzip = /* #__PURE__ */ (function () {
    return foldr(function (v) {
        return function (v1) {
            return new Data_Tuple.Tuple(new Data_List_Types.Cons(v.value0, v1.value0), new Data_List_Types.Cons(v.value1, v1.value1));
        };
    })(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_List_Types.Nil.value));
})();
var uncons = function (v) {
    if (v instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Data_List_Types.Cons) {
        return new Data_Maybe.Just({
            head: v.value0,
            tail: v.value1
        });
    };
    throw new Error("Failed pattern match at Data.List (line 259, column 1 - line 259, column 66): " + [ v.constructor.name ]);
};
var toUnfoldable = function (dictUnfoldable) {
    return Data_Unfoldable.unfoldr(dictUnfoldable)(function (xs) {
        return map(function (rec) {
            return new Data_Tuple.Tuple(rec.head, rec.tail);
        })(uncons(xs));
    });
};
var tail = function (v) {
    if (v instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Data_List_Types.Cons) {
        return new Data_Maybe.Just(v.value1);
    };
    throw new Error("Failed pattern match at Data.List (line 245, column 1 - line 245, column 43): " + [ v.constructor.name ]);
};
var stripPrefix = function (dictEq) {
    var eq2 = Data_Eq.eq(dictEq);
    return function (v) {
        return function (s) {
            var go = function (prefix) {
                return function (input) {
                    if (prefix instanceof Data_List_Types.Cons && (input instanceof Data_List_Types.Cons && eq2(prefix.value0)(input.value0))) {
                        return new Data_Maybe.Just(new Control_Monad_Rec_Class.Loop({
                            a: prefix.value1,
                            b: input.value1
                        }));
                    };
                    if (prefix instanceof Data_List_Types.Nil) {
                        return new Data_Maybe.Just(new Control_Monad_Rec_Class.Done(input));
                    };
                    return Data_Maybe.Nothing.value;
                };
            };
            return tailRecM2(go)(v)(s);
        };
    };
};
var span = function (v) {
    return function (v1) {
        if (v1 instanceof Data_List_Types.Cons && v(v1.value0)) {
            var v2 = span(v)(v1.value1);
            return {
                init: new Data_List_Types.Cons(v1.value0, v2.init),
                rest: v2.rest
            };
        };
        return {
            init: Data_List_Types.Nil.value,
            rest: v1
        };
    };
};
var snoc = function (xs) {
    return function (x) {
        return foldr(Data_List_Types.Cons.create)(new Data_List_Types.Cons(x, Data_List_Types.Nil.value))(xs);
    };
};
var singleton = function (a) {
    return new Data_List_Types.Cons(a, Data_List_Types.Nil.value);
};
var sortBy = function (cmp) {
    var merge = function (v) {
        return function (v1) {
            if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                if (eq(cmp(v.value0)(v1.value0))(Data_Ordering.GT.value)) {
                    return new Data_List_Types.Cons(v1.value0, merge(v)(v1.value1));
                };
                if (Data_Boolean.otherwise) {
                    return new Data_List_Types.Cons(v.value0, merge(v.value1)(v1));
                };
            };
            if (v instanceof Data_List_Types.Nil) {
                return v1;
            };
            if (v1 instanceof Data_List_Types.Nil) {
                return v;
            };
            throw new Error("Failed pattern match at Data.List (line 466, column 3 - line 466, column 38): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
    var mergePairs = function (v) {
        if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Cons) {
            return new Data_List_Types.Cons(merge(v.value0)(v.value1.value0), mergePairs(v.value1.value1));
        };
        return v;
    };
    var mergeAll = function ($copy_v) {
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v) {
            if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
                $tco_done = true;
                return v.value0;
            };
            $copy_v = mergePairs(v);
            return;
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($copy_v);
        };
        return $tco_result;
    };
    var sequences = function (v) {
        if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Cons) {
            if (eq(cmp(v.value0)(v.value1.value0))(Data_Ordering.GT.value)) {
                return descending(v.value1.value0)(singleton(v.value0))(v.value1.value1);
            };
            if (Data_Boolean.otherwise) {
                return ascending(v.value1.value0)(function (v1) {
                    return new Data_List_Types.Cons(v.value0, v1);
                })(v.value1.value1);
            };
        };
        return singleton(v);
    };
    var descending = function ($copy_a) {
        return function ($copy_as) {
            return function ($copy_v) {
                var $tco_var_a = $copy_a;
                var $tco_var_as = $copy_as;
                var $tco_done1 = false;
                var $tco_result;
                function $tco_loop(a, as, v) {
                    if (v instanceof Data_List_Types.Cons && eq(cmp(a)(v.value0))(Data_Ordering.GT.value)) {
                        $tco_var_a = v.value0;
                        $tco_var_as = new Data_List_Types.Cons(a, as);
                        $copy_v = v.value1;
                        return;
                    };
                    $tco_done1 = true;
                    return new Data_List_Types.Cons(new Data_List_Types.Cons(a, as), sequences(v));
                };
                while (!$tco_done1) {
                    $tco_result = $tco_loop($tco_var_a, $tco_var_as, $copy_v);
                };
                return $tco_result;
            };
        };
    };
    var ascending = function ($copy_a) {
        return function ($copy_as) {
            return function ($copy_v) {
                var $tco_var_a = $copy_a;
                var $tco_var_as = $copy_as;
                var $tco_done2 = false;
                var $tco_result;
                function $tco_loop(a, as, v) {
                    if (v instanceof Data_List_Types.Cons && notEq(cmp(a)(v.value0))(Data_Ordering.GT.value)) {
                        $tco_var_a = v.value0;
                        $tco_var_as = function (ys) {
                            return as(new Data_List_Types.Cons(a, ys));
                        };
                        $copy_v = v.value1;
                        return;
                    };
                    $tco_done2 = true;
                    return new Data_List_Types.Cons(as(singleton(a)), sequences(v));
                };
                while (!$tco_done2) {
                    $tco_result = $tco_loop($tco_var_a, $tco_var_as, $copy_v);
                };
                return $tco_result;
            };
        };
    };
    return function ($431) {
        return mergeAll(sequences($431));
    };
};
var sort = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (xs) {
        return sortBy(compare)(xs);
    };
};
var tails = function (v) {
    if (v instanceof Data_List_Types.Nil) {
        return singleton(Data_List_Types.Nil.value);
    };
    if (v instanceof Data_List_Types.Cons) {
        return new Data_List_Types.Cons(v, tails(v.value1));
    };
    throw new Error("Failed pattern match at Data.List (line 641, column 1 - line 641, column 43): " + [ v.constructor.name ]);
};
var showPattern = function (dictShow) {
    var show = Data_Show.show(Data_List_Types.showList(dictShow));
    return {
        show: function (v) {
            return "(Pattern " + (show(v) + ")");
        }
    };
};
var reverse = /* #__PURE__ */ (function () {
    var go = function ($copy_acc) {
        return function ($copy_v) {
            var $tco_var_acc = $copy_acc;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(acc, v) {
                if (v instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return acc;
                };
                if (v instanceof Data_List_Types.Cons) {
                    $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                    $copy_v = v.value1;
                    return;
                };
                throw new Error("Failed pattern match at Data.List (line 368, column 3 - line 368, column 19): " + [ acc.constructor.name, v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_acc, $copy_v);
            };
            return $tco_result;
        };
    };
    return go(Data_List_Types.Nil.value);
})();
var take = /* #__PURE__ */ (function () {
    var go = function ($copy_acc) {
        return function ($copy_v) {
            return function ($copy_v1) {
                var $tco_var_acc = $copy_acc;
                var $tco_var_v = $copy_v;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(acc, v, v1) {
                    if (v < 1) {
                        $tco_done = true;
                        return reverse(acc);
                    };
                    if (v1 instanceof Data_List_Types.Nil) {
                        $tco_done = true;
                        return reverse(acc);
                    };
                    if (v1 instanceof Data_List_Types.Cons) {
                        $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
                        $tco_var_v = v - 1 | 0;
                        $copy_v1 = v1.value1;
                        return;
                    };
                    throw new Error("Failed pattern match at Data.List (line 513, column 3 - line 513, column 35): " + [ acc.constructor.name, v.constructor.name, v1.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_acc, $tco_var_v, $copy_v1);
                };
                return $tco_result;
            };
        };
    };
    return go(Data_List_Types.Nil.value);
})();
var takeWhile = function (p) {
    var go = function ($copy_acc) {
        return function ($copy_v) {
            var $tco_var_acc = $copy_acc;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(acc, v) {
                if (v instanceof Data_List_Types.Cons && p(v.value0)) {
                    $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                    $copy_v = v.value1;
                    return;
                };
                $tco_done = true;
                return reverse(acc);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_acc, $copy_v);
            };
            return $tco_result;
        };
    };
    return go(Data_List_Types.Nil.value);
};
var unsnoc = function (lst) {
    var go = function ($copy_v) {
        return function ($copy_v1) {
            var $tco_var_v = $copy_v;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v, v1) {
                if (v instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return Data_Maybe.Nothing.value;
                };
                if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return new Data_Maybe.Just({
                        revInit: v1,
                        last: v.value0
                    });
                };
                if (v instanceof Data_List_Types.Cons) {
                    $tco_var_v = v.value1;
                    $copy_v1 = new Data_List_Types.Cons(v.value0, v1);
                    return;
                };
                throw new Error("Failed pattern match at Data.List (line 270, column 3 - line 270, column 21): " + [ v.constructor.name, v1.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $copy_v1);
            };
            return $tco_result;
        };
    };
    return map(function (h) {
        return {
            init: reverse(h.revInit),
            last: h.last
        };
    })(go(lst)(Data_List_Types.Nil.value));
};
var zipWith = function (f) {
    return function (xs) {
        return function (ys) {
            var go = function ($copy_v) {
                return function ($copy_v1) {
                    return function ($copy_acc) {
                        var $tco_var_v = $copy_v;
                        var $tco_var_v1 = $copy_v1;
                        var $tco_done = false;
                        var $tco_result;
                        function $tco_loop(v, v1, acc) {
                            if (v instanceof Data_List_Types.Nil) {
                                $tco_done = true;
                                return acc;
                            };
                            if (v1 instanceof Data_List_Types.Nil) {
                                $tco_done = true;
                                return acc;
                            };
                            if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                                $tco_var_v = v.value1;
                                $tco_var_v1 = v1.value1;
                                $copy_acc = new Data_List_Types.Cons(f(v.value0)(v1.value0), acc);
                                return;
                            };
                            throw new Error("Failed pattern match at Data.List (line 779, column 3 - line 779, column 21): " + [ v.constructor.name, v1.constructor.name, acc.constructor.name ]);
                        };
                        while (!$tco_done) {
                            $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_acc);
                        };
                        return $tco_result;
                    };
                };
            };
            return reverse(go(xs)(ys)(Data_List_Types.Nil.value));
        };
    };
};
var zip = /* #__PURE__ */ (function () {
    return zipWith(Data_Tuple.Tuple.create);
})();
var zipWithA = function (dictApplicative) {
    var sequence1 = sequence(dictApplicative);
    return function (f) {
        return function (xs) {
            return function (ys) {
                return sequence1(zipWith(f)(xs)(ys));
            };
        };
    };
};
var range = function (start) {
    return function (end) {
        if (start === end) {
            return singleton(start);
        };
        if (Data_Boolean.otherwise) {
            var go = function ($copy_s) {
                return function ($copy_e) {
                    return function ($copy_step) {
                        return function ($copy_rest) {
                            var $tco_var_s = $copy_s;
                            var $tco_var_e = $copy_e;
                            var $tco_var_step = $copy_step;
                            var $tco_done = false;
                            var $tco_result;
                            function $tco_loop(s, e, step, rest) {
                                if (s === e) {
                                    $tco_done = true;
                                    return new Data_List_Types.Cons(s, rest);
                                };
                                if (Data_Boolean.otherwise) {
                                    $tco_var_s = s + step | 0;
                                    $tco_var_e = e;
                                    $tco_var_step = step;
                                    $copy_rest = new Data_List_Types.Cons(s, rest);
                                    return;
                                };
                                throw new Error("Failed pattern match at Data.List (line 148, column 3 - line 149, column 65): " + [ s.constructor.name, e.constructor.name, step.constructor.name, rest.constructor.name ]);
                            };
                            while (!$tco_done) {
                                $tco_result = $tco_loop($tco_var_s, $tco_var_e, $tco_var_step, $copy_rest);
                            };
                            return $tco_result;
                        };
                    };
                };
            };
            return go(end)(start)((function () {
                var $312 = start > end;
                if ($312) {
                    return 1;
                };
                return -1 | 0;
            })())(Data_List_Types.Nil.value);
        };
        throw new Error("Failed pattern match at Data.List (line 144, column 1 - line 144, column 32): " + [ start.constructor.name, end.constructor.name ]);
    };
};
var partition = function (p) {
    return function (xs) {
        var select = function (x) {
            return function (v) {
                var $315 = p(x);
                if ($315) {
                    return {
                        no: v.no,
                        yes: new Data_List_Types.Cons(x, v.yes)
                    };
                };
                return {
                    no: new Data_List_Types.Cons(x, v.no),
                    yes: v.yes
                };
            };
        };
        return foldr(select)({
            no: Data_List_Types.Nil.value,
            yes: Data_List_Types.Nil.value
        })(xs);
    };
};
var $$null = function (v) {
    if (v instanceof Data_List_Types.Nil) {
        return true;
    };
    return false;
};
var nubBy = function (p) {
    var go = function ($copy_v) {
        return function ($copy_acc) {
            return function ($copy_v1) {
                var $tco_var_v = $copy_v;
                var $tco_var_acc = $copy_acc;
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(v, acc, v1) {
                    if (v1 instanceof Data_List_Types.Nil) {
                        $tco_done = true;
                        return acc;
                    };
                    if (v1 instanceof Data_List_Types.Cons) {
                        var v2 = Data_List_Internal.insertAndLookupBy(p)(v1.value0)(v);
                        if (v2.found) {
                            $tco_var_v = v2.result;
                            $tco_var_acc = acc;
                            $copy_v1 = v1.value1;
                            return;
                        };
                        $tco_var_v = v2.result;
                        $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
                        $copy_v1 = v1.value1;
                        return;
                    };
                    throw new Error("Failed pattern match at Data.List (line 673, column 5 - line 673, column 23): " + [ v.constructor.name, acc.constructor.name, v1.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($tco_var_v, $tco_var_acc, $copy_v1);
                };
                return $tco_result;
            };
        };
    };
    var $432 = go(Data_List_Internal.emptySet)(Data_List_Types.Nil.value);
    return function ($433) {
        return reverse($432($433));
    };
};
var nub = function (dictOrd) {
    return nubBy(Data_Ord.compare(dictOrd));
};
var newtypePattern = {
    Coercible0: function () {
        return undefined;
    }
};
var mapMaybe = function (f) {
    var go = function ($copy_acc) {
        return function ($copy_v) {
            var $tco_var_acc = $copy_acc;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(acc, v) {
                if (v instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return reverse(acc);
                };
                if (v instanceof Data_List_Types.Cons) {
                    var v1 = f(v.value0);
                    if (v1 instanceof Data_Maybe.Nothing) {
                        $tco_var_acc = acc;
                        $copy_v = v.value1;
                        return;
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
                        $copy_v = v.value1;
                        return;
                    };
                    throw new Error("Failed pattern match at Data.List (line 419, column 5 - line 421, column 32): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.List (line 417, column 3 - line 417, column 27): " + [ acc.constructor.name, v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_acc, $copy_v);
            };
            return $tco_result;
        };
    };
    return go(Data_List_Types.Nil.value);
};
var manyRec = function (dictMonadRec) {
    var bind1 = Control_Bind.bind((dictMonadRec.Monad0()).Bind1());
    var tailRecM = Control_Monad_Rec_Class.tailRecM(dictMonadRec);
    return function (dictAlternative) {
        var Alt0 = (dictAlternative.Plus1()).Alt0();
        var alt = Control_Alt.alt(Alt0);
        var map1 = Data_Functor.map(Alt0.Functor0());
        var pure = Control_Applicative.pure(dictAlternative.Applicative0());
        return function (p) {
            var go = function (acc) {
                return bind1(alt(map1(Control_Monad_Rec_Class.Loop.create)(p))(pure(new Control_Monad_Rec_Class.Done(Data_Unit.unit))))(function (aa) {
                    return pure(bimap(function (v) {
                        return new Data_List_Types.Cons(v, acc);
                    })(function (v) {
                        return reverse(acc);
                    })(aa));
                });
            };
            return tailRecM(go)(Data_List_Types.Nil.value);
        };
    };
};
var someRec = function (dictMonadRec) {
    var manyRec1 = manyRec(dictMonadRec);
    return function (dictAlternative) {
        var apply = Control_Apply.apply((dictAlternative.Applicative0()).Apply0());
        var map1 = Data_Functor.map(((dictAlternative.Plus1()).Alt0()).Functor0());
        var manyRec2 = manyRec1(dictAlternative);
        return function (v) {
            return apply(map1(Data_List_Types.Cons.create)(v))(manyRec2(v));
        };
    };
};
var some = function (dictAlternative) {
    var apply = Control_Apply.apply((dictAlternative.Applicative0()).Apply0());
    var map1 = Data_Functor.map(((dictAlternative.Plus1()).Alt0()).Functor0());
    return function (dictLazy) {
        var defer = Control_Lazy.defer(dictLazy);
        return function (v) {
            return apply(map1(Data_List_Types.Cons.create)(v))(defer(function (v1) {
                return many(dictAlternative)(dictLazy)(v);
            }));
        };
    };
};
var many = function (dictAlternative) {
    var alt = Control_Alt.alt((dictAlternative.Plus1()).Alt0());
    var pure = Control_Applicative.pure(dictAlternative.Applicative0());
    return function (dictLazy) {
        return function (v) {
            return alt(some(dictAlternative)(dictLazy)(v))(pure(Data_List_Types.Nil.value));
        };
    };
};
var length = /* #__PURE__ */ foldl(function (acc) {
    return function (v) {
        return acc + 1 | 0;
    };
})(0);
var last = function ($copy_v) {
    var $tco_done = false;
    var $tco_result;
    function $tco_loop(v) {
        if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return new Data_Maybe.Just(v.value0);
        };
        if (v instanceof Data_List_Types.Cons) {
            $copy_v = v.value1;
            return;
        };
        $tco_done = true;
        return Data_Maybe.Nothing.value;
    };
    while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
    };
    return $tco_result;
};
var insertBy = function (v) {
    return function (x) {
        return function (v1) {
            if (v1 instanceof Data_List_Types.Nil) {
                return singleton(x);
            };
            if (v1 instanceof Data_List_Types.Cons) {
                var v2 = v(x)(v1.value0);
                if (v2 instanceof Data_Ordering.GT) {
                    return new Data_List_Types.Cons(v1.value0, insertBy(v)(x)(v1.value1));
                };
                return new Data_List_Types.Cons(x, v1);
            };
            throw new Error("Failed pattern match at Data.List (line 216, column 1 - line 216, column 68): " + [ v.constructor.name, x.constructor.name, v1.constructor.name ]);
        };
    };
};
var insertAt = function (v) {
    return function (v1) {
        return function (v2) {
            if (v === 0) {
                return new Data_Maybe.Just(new Data_List_Types.Cons(v1, v2));
            };
            if (v2 instanceof Data_List_Types.Cons) {
                return map(function (v3) {
                    return new Data_List_Types.Cons(v2.value0, v3);
                })(insertAt(v - 1 | 0)(v1)(v2.value1));
            };
            return Data_Maybe.Nothing.value;
        };
    };
};
var insert = function (dictOrd) {
    return insertBy(Data_Ord.compare(dictOrd));
};
var init = function (lst) {
    return map(function (v) {
        return v.init;
    })(unsnoc(lst));
};
var index = function ($copy_v) {
    return function ($copy_v1) {
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v, v1) {
            if (v instanceof Data_List_Types.Nil) {
                $tco_done = true;
                return Data_Maybe.Nothing.value;
            };
            if (v instanceof Data_List_Types.Cons && v1 === 0) {
                $tco_done = true;
                return new Data_Maybe.Just(v.value0);
            };
            if (v instanceof Data_List_Types.Cons) {
                $tco_var_v = v.value1;
                $copy_v1 = v1 - 1 | 0;
                return;
            };
            throw new Error("Failed pattern match at Data.List (line 281, column 1 - line 281, column 44): " + [ v.constructor.name, v1.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_v, $copy_v1);
        };
        return $tco_result;
    };
};
var head = function (v) {
    if (v instanceof Data_List_Types.Nil) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Data_List_Types.Cons) {
        return new Data_Maybe.Just(v.value0);
    };
    throw new Error("Failed pattern match at Data.List (line 230, column 1 - line 230, column 22): " + [ v.constructor.name ]);
};
var transpose = function (v) {
    if (v instanceof Data_List_Types.Nil) {
        return Data_List_Types.Nil.value;
    };
    if (v instanceof Data_List_Types.Cons && v.value0 instanceof Data_List_Types.Nil) {
        return transpose(v.value1);
    };
    if (v instanceof Data_List_Types.Cons && v.value0 instanceof Data_List_Types.Cons) {
        return new Data_List_Types.Cons(new Data_List_Types.Cons(v.value0.value0, mapMaybe(head)(v.value1)), transpose(new Data_List_Types.Cons(v.value0.value1, mapMaybe(tail)(v.value1))));
    };
    throw new Error("Failed pattern match at Data.List (line 813, column 1 - line 813, column 54): " + [ v.constructor.name ]);
};
var groupBy = function (v) {
    return function (v1) {
        if (v1 instanceof Data_List_Types.Nil) {
            return Data_List_Types.Nil.value;
        };
        if (v1 instanceof Data_List_Types.Cons) {
            var v2 = span(v(v1.value0))(v1.value1);
            return new Data_List_Types.Cons(new Data_NonEmpty.NonEmpty(v1.value0, v2.init), groupBy(v)(v2.rest));
        };
        throw new Error("Failed pattern match at Data.List (line 609, column 1 - line 609, column 80): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var groupAllBy = function (p) {
    var $434 = groupBy(function (x) {
        return function (y) {
            return eq(p(x)(y))(Data_Ordering.EQ.value);
        };
    });
    var $435 = sortBy(p);
    return function ($436) {
        return $434($435($436));
    };
};
var group = function (dictEq) {
    return groupBy(Data_Eq.eq(dictEq));
};
var groupAll = function (dictOrd) {
    var $437 = group(dictOrd.Eq0());
    var $438 = sort(dictOrd);
    return function ($439) {
        return $437($438($439));
    };
};
var fromFoldable = function (dictFoldable) {
    return Data_Foldable.foldr(dictFoldable)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
};
var foldM = function (dictMonad) {
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    var bind1 = Control_Bind.bind(dictMonad.Bind1());
    return function (v) {
        return function (b) {
            return function (v1) {
                if (v1 instanceof Data_List_Types.Nil) {
                    return pure(b);
                };
                if (v1 instanceof Data_List_Types.Cons) {
                    return bind1(v(b)(v1.value0))(function (b$prime) {
                        return foldM(dictMonad)(v)(b$prime)(v1.value1);
                    });
                };
                throw new Error("Failed pattern match at Data.List (line 824, column 1 - line 824, column 72): " + [ v.constructor.name, b.constructor.name, v1.constructor.name ]);
            };
        };
    };
};
var findIndex = function (fn) {
    var go = function ($copy_v) {
        return function ($copy_v1) {
            var $tco_var_v = $copy_v;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v, v1) {
                if (v1 instanceof Data_List_Types.Cons) {
                    if (fn(v1.value0)) {
                        $tco_done = true;
                        return new Data_Maybe.Just(v);
                    };
                    if (Data_Boolean.otherwise) {
                        $tco_var_v = v + 1 | 0;
                        $copy_v1 = v1.value1;
                        return;
                    };
                };
                if (v1 instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at Data.List (line 301, column 3 - line 301, column 35): " + [ v.constructor.name, v1.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $copy_v1);
            };
            return $tco_result;
        };
    };
    return go(0);
};
var findLastIndex = function (fn) {
    return function (xs) {
        return map(function (v) {
            return (length(xs) - 1 | 0) - v | 0;
        })(findIndex(fn)(reverse(xs)));
    };
};
var filterM = function (dictMonad) {
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    var bind1 = Control_Bind.bind(dictMonad.Bind1());
    return function (v) {
        return function (v1) {
            if (v1 instanceof Data_List_Types.Nil) {
                return pure(Data_List_Types.Nil.value);
            };
            if (v1 instanceof Data_List_Types.Cons) {
                return bind1(v(v1.value0))(function (b) {
                    return bind1(filterM(dictMonad)(v)(v1.value1))(function (xs$prime) {
                        return pure((function () {
                            if (b) {
                                return new Data_List_Types.Cons(v1.value0, xs$prime);
                            };
                            return xs$prime;
                        })());
                    });
                });
            };
            throw new Error("Failed pattern match at Data.List (line 403, column 1 - line 403, column 75): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var filter = function (p) {
    var go = function ($copy_acc) {
        return function ($copy_v) {
            var $tco_var_acc = $copy_acc;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(acc, v) {
                if (v instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return reverse(acc);
                };
                if (v instanceof Data_List_Types.Cons) {
                    if (p(v.value0)) {
                        $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                        $copy_v = v.value1;
                        return;
                    };
                    if (Data_Boolean.otherwise) {
                        $tco_var_acc = acc;
                        $copy_v = v.value1;
                        return;
                    };
                };
                throw new Error("Failed pattern match at Data.List (line 390, column 3 - line 390, column 27): " + [ acc.constructor.name, v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_acc, $copy_v);
            };
            return $tco_result;
        };
    };
    return go(Data_List_Types.Nil.value);
};
var intersectBy = function (v) {
    return function (v1) {
        return function (v2) {
            if (v1 instanceof Data_List_Types.Nil) {
                return Data_List_Types.Nil.value;
            };
            if (v2 instanceof Data_List_Types.Nil) {
                return Data_List_Types.Nil.value;
            };
            return filter(function (x) {
                return any(v(x))(v2);
            })(v1);
        };
    };
};
var intersect = function (dictEq) {
    return intersectBy(Data_Eq.eq(dictEq));
};
var nubByEq = function (v) {
    return function (v1) {
        if (v1 instanceof Data_List_Types.Nil) {
            return Data_List_Types.Nil.value;
        };
        if (v1 instanceof Data_List_Types.Cons) {
            return new Data_List_Types.Cons(v1.value0, nubByEq(v)(filter(function (y) {
                return !v(v1.value0)(y);
            })(v1.value1)));
        };
        throw new Error("Failed pattern match at Data.List (line 705, column 1 - line 705, column 61): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var nubEq = function (dictEq) {
    return nubByEq(Data_Eq.eq(dictEq));
};
var eqPattern = function (dictEq) {
    var eq2 = Data_Eq.eq(Data_List_Types.eqList(dictEq));
    return {
        eq: function (x) {
            return function (y) {
                return eq2(x)(y);
            };
        }
    };
};
var ordPattern = function (dictOrd) {
    var compare = Data_Ord.compare(Data_List_Types.ordList(dictOrd));
    var eqPattern1 = eqPattern(dictOrd.Eq0());
    return {
        compare: function (x) {
            return function (y) {
                return compare(x)(y);
            };
        },
        Eq0: function () {
            return eqPattern1;
        }
    };
};
var elemLastIndex = function (dictEq) {
    var eq2 = Data_Eq.eq(dictEq);
    return function (x) {
        return findLastIndex(function (v) {
            return eq2(v)(x);
        });
    };
};
var elemIndex = function (dictEq) {
    var eq2 = Data_Eq.eq(dictEq);
    return function (x) {
        return findIndex(function (v) {
            return eq2(v)(x);
        });
    };
};
var dropWhile = function (p) {
    var go = function ($copy_v) {
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v) {
            if (v instanceof Data_List_Types.Cons && p(v.value0)) {
                $copy_v = v.value1;
                return;
            };
            $tco_done = true;
            return v;
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($copy_v);
        };
        return $tco_result;
    };
    return go;
};
var dropEnd = function (n) {
    return function (xs) {
        return take(length(xs) - n | 0)(xs);
    };
};
var drop = function ($copy_v) {
    return function ($copy_v1) {
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v, v1) {
            if (v < 1) {
                $tco_done = true;
                return v1;
            };
            if (v1 instanceof Data_List_Types.Nil) {
                $tco_done = true;
                return Data_List_Types.Nil.value;
            };
            if (v1 instanceof Data_List_Types.Cons) {
                $tco_var_v = v - 1 | 0;
                $copy_v1 = v1.value1;
                return;
            };
            throw new Error("Failed pattern match at Data.List (line 536, column 1 - line 536, column 42): " + [ v.constructor.name, v1.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_v, $copy_v1);
        };
        return $tco_result;
    };
};
var slice = function (start) {
    return function (end) {
        return function (xs) {
            return take(end - start | 0)(drop(start)(xs));
        };
    };
};
var takeEnd = function (n) {
    return function (xs) {
        return drop(length(xs) - n | 0)(xs);
    };
};
var deleteBy = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof Data_List_Types.Nil) {
                return Data_List_Types.Nil.value;
            };
            if (v2 instanceof Data_List_Types.Cons && v(v1)(v2.value0)) {
                return v2.value1;
            };
            if (v2 instanceof Data_List_Types.Cons) {
                return new Data_List_Types.Cons(v2.value0, deleteBy(v)(v1)(v2.value1));
            };
            throw new Error("Failed pattern match at Data.List (line 732, column 1 - line 732, column 67): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var unionBy = function (eq2) {
    return function (xs) {
        return function (ys) {
            return append1(xs)(foldl(Data_Function.flip(deleteBy(eq2)))(nubByEq(eq2)(ys))(xs));
        };
    };
};
var union = function (dictEq) {
    return unionBy(Data_Eq.eq(dictEq));
};
var deleteAt = function (v) {
    return function (v1) {
        if (v === 0 && v1 instanceof Data_List_Types.Cons) {
            return new Data_Maybe.Just(v1.value1);
        };
        if (v1 instanceof Data_List_Types.Cons) {
            return map(function (v2) {
                return new Data_List_Types.Cons(v1.value0, v2);
            })(deleteAt(v - 1 | 0)(v1.value1));
        };
        return Data_Maybe.Nothing.value;
    };
};
var $$delete = function (dictEq) {
    return deleteBy(Data_Eq.eq(dictEq));
};
var difference = function (dictEq) {
    return foldl(Data_Function.flip($$delete(dictEq)));
};
var concatMap = /* #__PURE__ */ Data_Function.flip(bind);
var concat = function (v) {
    return bind(v)(identity);
};
var catMaybes = /* #__PURE__ */ mapMaybe(identity);
var alterAt = function (v) {
    return function (v1) {
        return function (v2) {
            if (v === 0 && v2 instanceof Data_List_Types.Cons) {
                return new Data_Maybe.Just((function () {
                    var v3 = v1(v2.value0);
                    if (v3 instanceof Data_Maybe.Nothing) {
                        return v2.value1;
                    };
                    if (v3 instanceof Data_Maybe.Just) {
                        return new Data_List_Types.Cons(v3.value0, v2.value1);
                    };
                    throw new Error("Failed pattern match at Data.List (line 352, column 3 - line 354, column 23): " + [ v3.constructor.name ]);
                })());
            };
            if (v2 instanceof Data_List_Types.Cons) {
                return map(function (v3) {
                    return new Data_List_Types.Cons(v2.value0, v3);
                })(alterAt(v - 1 | 0)(v1)(v2.value1));
            };
            return Data_Maybe.Nothing.value;
        };
    };
};
var modifyAt = function (n) {
    return function (f) {
        return alterAt(n)(function ($440) {
            return Data_Maybe.Just.create(f($440));
        });
    };
};
export {
    toUnfoldable,
    fromFoldable,
    singleton,
    range,
    some,
    someRec,
    many,
    manyRec,
    $$null as null,
    length,
    snoc,
    insert,
    insertBy,
    head,
    last,
    tail,
    init,
    uncons,
    unsnoc,
    index,
    elemIndex,
    elemLastIndex,
    findIndex,
    findLastIndex,
    insertAt,
    deleteAt,
    updateAt,
    modifyAt,
    alterAt,
    reverse,
    concat,
    concatMap,
    filter,
    filterM,
    mapMaybe,
    catMaybes,
    sort,
    sortBy,
    Pattern,
    stripPrefix,
    slice,
    take,
    takeEnd,
    takeWhile,
    drop,
    dropEnd,
    dropWhile,
    span,
    group,
    groupAll,
    groupBy,
    groupAllBy,
    partition,
    nub,
    nubBy,
    nubEq,
    nubByEq,
    union,
    unionBy,
    $$delete as delete,
    deleteBy,
    difference,
    intersect,
    intersectBy,
    zipWith,
    zipWithA,
    zip,
    unzip,
    transpose,
    foldM,
    eqPattern,
    ordPattern,
    newtypePattern,
    showPattern
};
export {
    all,
    any,
    elem,
    find,
    findMap,
    fold,
    foldMap,
    foldl,
    foldr,
    intercalate,
    notElem
} from "../Data.Foldable/index.js";
export {
    Cons,
    Nil
} from "../Data.List.Types/index.js";
export {
    scanl,
    scanr
} from "../Data.Traversable/index.js";
