// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as Affjax_RequestBody from "../Affjax.RequestBody/index.js";
import * as Affjax_RequestHeader from "../Affjax.RequestHeader/index.js";
import * as Affjax_ResponseFormat from "../Affjax.ResponseFormat/index.js";
import * as Affjax_ResponseHeader from "../Affjax.ResponseHeader/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Error_Class from "../Control.Monad.Error.Class/index.js";
import * as Control_Monad_Except from "../Control.Monad.Except/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Parser from "../Data.Argonaut.Parser/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_FormURLEncoded from "../Data.FormURLEncoded/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HTTP_Method from "../Data.HTTP.Method/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_List_NonEmpty from "../Data.List.NonEmpty/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Compat from "../Effect.Aff.Compat/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Foreign from "../Foreign/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity));
var fail = /* #__PURE__ */ Foreign.fail(Data_Identity.monadIdentity);
var unsafeReadTagged = /* #__PURE__ */ Foreign.unsafeReadTagged(Data_Identity.monadIdentity);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity));
var composeKleisliFlipped = /* #__PURE__ */ Control_Bind.composeKleisliFlipped(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity));
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var any = /* #__PURE__ */ Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var eq = /* #__PURE__ */ Data_Eq.eq(Data_Eq.eqString);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Data_Maybe.bindMaybe);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Effect_Aff.functorAff);
var $$try = /* #__PURE__ */ Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var map2 = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var $$void = /* #__PURE__ */ Data_Functor["void"](Data_Either.functorEither);
var RequestContentError = /* #__PURE__ */ (function () {
    function RequestContentError(value0) {
        this.value0 = value0;
    };
    RequestContentError.create = function (value0) {
        return new RequestContentError(value0);
    };
    return RequestContentError;
})();
var ResponseBodyError = /* #__PURE__ */ (function () {
    function ResponseBodyError(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ResponseBodyError.create = function (value0) {
        return function (value1) {
            return new ResponseBodyError(value0, value1);
        };
    };
    return ResponseBodyError;
})();
var TimeoutError = /* #__PURE__ */ (function () {
    function TimeoutError() {

    };
    TimeoutError.value = new TimeoutError();
    return TimeoutError;
})();
var RequestFailedError = /* #__PURE__ */ (function () {
    function RequestFailedError() {

    };
    RequestFailedError.value = new RequestFailedError();
    return RequestFailedError;
})();
var XHROtherError = /* #__PURE__ */ (function () {
    function XHROtherError(value0) {
        this.value0 = value0;
    };
    XHROtherError.create = function (value0) {
        return new XHROtherError(value0);
    };
    return XHROtherError;
})();
var request = function (driver) {
    return function (req) {
        var parseJSON = function (v) {
            if (v === "") {
                return pure(Data_Argonaut_Core.jsonEmptyObject);
            };
            return Data_Either.either(function ($74) {
                return fail(Foreign.ForeignError.create($74));
            })(pure)(Data_Argonaut_Parser.jsonParser(v));
        };
        var fromResponse = (function () {
            if (req.responseFormat instanceof Affjax_ResponseFormat["ArrayBuffer"]) {
                return unsafeReadTagged("ArrayBuffer");
            };
            if (req.responseFormat instanceof Affjax_ResponseFormat.Blob) {
                return unsafeReadTagged("Blob");
            };
            if (req.responseFormat instanceof Affjax_ResponseFormat.Document) {
                return function (x) {
                    return alt(unsafeReadTagged("Document")(x))(alt(unsafeReadTagged("XMLDocument")(x))(unsafeReadTagged("HTMLDocument")(x)));
                };
            };
            if (req.responseFormat instanceof Affjax_ResponseFormat.Json) {
                return composeKleisliFlipped(function ($75) {
                    return req.responseFormat.value0(parseJSON($75));
                })(unsafeReadTagged("String"));
            };
            if (req.responseFormat instanceof Affjax_ResponseFormat["String"]) {
                return unsafeReadTagged("String");
            };
            if (req.responseFormat instanceof Affjax_ResponseFormat.Ignore) {
                return Data_Function["const"](req.responseFormat.value0(pure(Data_Unit.unit)));
            };
            throw new Error("Failed pattern match at Affjax (line 274, column 18 - line 283, column 57): " + [ req.responseFormat.constructor.name ]);
        })();
        var extractContent = function (v) {
            if (v instanceof Affjax_RequestBody.ArrayView) {
                return new Data_Either.Right(v.value0(Foreign.unsafeToForeign));
            };
            if (v instanceof Affjax_RequestBody.Blob) {
                return new Data_Either.Right(Foreign.unsafeToForeign(v.value0));
            };
            if (v instanceof Affjax_RequestBody.Document) {
                return new Data_Either.Right(Foreign.unsafeToForeign(v.value0));
            };
            if (v instanceof Affjax_RequestBody["String"]) {
                return new Data_Either.Right(Foreign.unsafeToForeign(v.value0));
            };
            if (v instanceof Affjax_RequestBody.FormData) {
                return new Data_Either.Right(Foreign.unsafeToForeign(v.value0));
            };
            if (v instanceof Affjax_RequestBody.FormURLEncoded) {
                return Data_Either.note("Body contains values that cannot be encoded as application/x-www-form-urlencoded")(map(Foreign.unsafeToForeign)(Data_FormURLEncoded.encode(v.value0)));
            };
            if (v instanceof Affjax_RequestBody.Json) {
                return new Data_Either.Right(Foreign.unsafeToForeign(Data_Argonaut_Core.stringify(v.value0)));
            };
            throw new Error("Failed pattern match at Affjax (line 235, column 20 - line 250, column 69): " + [ v.constructor.name ]);
        };
        var addHeader = function (mh) {
            return function (hs) {
                if (mh instanceof Data_Maybe.Just && !any(Data_Function.on(eq)(Affjax_RequestHeader.name)(mh.value0))(hs)) {
                    return Data_Array.snoc(hs)(mh.value0);
                };
                return hs;
            };
        };
        var headers = function (reqContent) {
            return addHeader(map(Affjax_RequestHeader.ContentType.create)(bindFlipped(Affjax_RequestBody.toMediaType)(reqContent)))(addHeader(map(Affjax_RequestHeader.Accept.create)(Affjax_ResponseFormat.toMediaType(req.responseFormat)))(req.headers));
        };
        var ajaxRequest = function (v) {
            return {
                method: Data_HTTP_Method.print(req.method),
                url: req.url,
                headers: map1(function (h) {
                    return {
                        field: Affjax_RequestHeader.name(h),
                        value: Affjax_RequestHeader.value(h)
                    };
                })(headers(req.content)),
                content: v,
                responseType: Affjax_ResponseFormat.toResponseType(req.responseFormat),
                username: Data_Nullable.toNullable(req.username),
                password: Data_Nullable.toNullable(req.password),
                withCredentials: req.withCredentials,
                timeout: Data_Maybe.fromMaybe(0.0)(map(function (v1) {
                    return v1;
                })(req.timeout))
            };
        };
        var send = function (content) {
            return mapFlipped($$try(Effect_Aff_Compat.fromEffectFnAff($foreign["_ajax"](driver, "AffjaxTimeoutErrorMessageIdent", "AffjaxRequestFailedMessageIdent", Affjax_ResponseHeader.ResponseHeader.create, ajaxRequest(content)))))(function (v) {
                if (v instanceof Data_Either.Right) {
                    var v1 = Control_Monad_Except.runExcept(fromResponse(v.value0.body));
                    if (v1 instanceof Data_Either.Left) {
                        return new Data_Either.Left(new ResponseBodyError(Data_List_NonEmpty.head(v1.value0), v.value0));
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return new Data_Either.Right({
                            body: v1.value0,
                            headers: v.value0.headers,
                            status: v.value0.status,
                            statusText: v.value0.statusText
                        });
                    };
                    throw new Error("Failed pattern match at Affjax (line 209, column 9 - line 211, column 52): " + [ v1.constructor.name ]);
                };
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left((function () {
                        var message = Effect_Exception.message(v.value0);
                        var $61 = message === "AffjaxTimeoutErrorMessageIdent";
                        if ($61) {
                            return TimeoutError.value;
                        };
                        var $62 = message === "AffjaxRequestFailedMessageIdent";
                        if ($62) {
                            return RequestFailedError.value;
                        };
                        return new XHROtherError(v.value0);
                    })());
                };
                throw new Error("Failed pattern match at Affjax (line 207, column 144 - line 219, column 28): " + [ v.constructor.name ]);
            });
        };
        if (req.content instanceof Data_Maybe.Nothing) {
            return send(Data_Nullable.toNullable(Data_Maybe.Nothing.value));
        };
        if (req.content instanceof Data_Maybe.Just) {
            var v = extractContent(req.content.value0);
            if (v instanceof Data_Either.Right) {
                return send(Data_Nullable.toNullable(new Data_Maybe.Just(v.value0)));
            };
            if (v instanceof Data_Either.Left) {
                return pure1(new Data_Either.Left(new RequestContentError(v.value0)));
            };
            throw new Error("Failed pattern match at Affjax (line 199, column 7 - line 203, column 48): " + [ v.constructor.name ]);
        };
        throw new Error("Failed pattern match at Affjax (line 195, column 3 - line 203, column 48): " + [ req.content.constructor.name ]);
    };
};
var printError = function (v) {
    if (v instanceof RequestContentError) {
        return "There was a problem with the request content: " + v.value0;
    };
    if (v instanceof ResponseBodyError) {
        return "There was a problem with the response body: " + Foreign.renderForeignError(v.value0);
    };
    if (v instanceof TimeoutError) {
        return "There was a problem making the request: timeout";
    };
    if (v instanceof RequestFailedError) {
        return "There was a problem making the request: request failed";
    };
    if (v instanceof XHROtherError) {
        return "There was a problem making the request: " + Effect_Exception.message(v.value0);
    };
    throw new Error("Failed pattern match at Affjax (line 113, column 14 - line 123, column 66): " + [ v.constructor.name ]);
};
var defaultRequest = /* #__PURE__ */ (function () {
    return {
        method: new Data_Either.Left(Data_HTTP_Method.GET.value),
        url: "/",
        headers: [  ],
        content: Data_Maybe.Nothing.value,
        username: Data_Maybe.Nothing.value,
        password: Data_Maybe.Nothing.value,
        withCredentials: false,
        responseFormat: Affjax_ResponseFormat.ignore,
        timeout: Data_Maybe.Nothing.value
    };
})();
var $$delete = function (driver) {
    return function (rf) {
        return function (u) {
            return request(driver)({
                method: new Data_Either.Left(Data_HTTP_Method.DELETE.value),
                url: u,
                headers: defaultRequest.headers,
                content: defaultRequest.content,
                username: defaultRequest.username,
                password: defaultRequest.password,
                withCredentials: defaultRequest.withCredentials,
                responseFormat: rf,
                timeout: defaultRequest.timeout
            });
        };
    };
};
var delete_ = function (driver) {
    var $76 = map2($$void);
    var $77 = $$delete(driver)(Affjax_ResponseFormat.ignore);
    return function ($78) {
        return $76($77($78));
    };
};
var get = function (driver) {
    return function (rf) {
        return function (u) {
            return request(driver)({
                method: defaultRequest.method,
                url: u,
                headers: defaultRequest.headers,
                content: defaultRequest.content,
                username: defaultRequest.username,
                password: defaultRequest.password,
                withCredentials: defaultRequest.withCredentials,
                responseFormat: rf,
                timeout: defaultRequest.timeout
            });
        };
    };
};
var patch = function (driver) {
    return function (rf) {
        return function (u) {
            return function (c) {
                return request(driver)({
                    method: new Data_Either.Left(Data_HTTP_Method.PATCH.value),
                    url: u,
                    headers: defaultRequest.headers,
                    content: new Data_Maybe.Just(c),
                    username: defaultRequest.username,
                    password: defaultRequest.password,
                    withCredentials: defaultRequest.withCredentials,
                    responseFormat: rf,
                    timeout: defaultRequest.timeout
                });
            };
        };
    };
};
var patch_ = function (driver) {
    return function (url) {
        var $79 = map2($$void);
        var $80 = patch(driver)(Affjax_ResponseFormat.ignore)(url);
        return function ($81) {
            return $79($80($81));
        };
    };
};
var post = function (driver) {
    return function (rf) {
        return function (u) {
            return function (c) {
                return request(driver)({
                    method: new Data_Either.Left(Data_HTTP_Method.POST.value),
                    url: u,
                    headers: defaultRequest.headers,
                    content: c,
                    username: defaultRequest.username,
                    password: defaultRequest.password,
                    withCredentials: defaultRequest.withCredentials,
                    responseFormat: rf,
                    timeout: defaultRequest.timeout
                });
            };
        };
    };
};
var post_ = function (driver) {
    return function (url) {
        var $82 = map2($$void);
        var $83 = post(driver)(Affjax_ResponseFormat.ignore)(url);
        return function ($84) {
            return $82($83($84));
        };
    };
};
var put = function (driver) {
    return function (rf) {
        return function (u) {
            return function (c) {
                return request(driver)({
                    method: new Data_Either.Left(Data_HTTP_Method.PUT.value),
                    url: u,
                    headers: defaultRequest.headers,
                    content: c,
                    username: defaultRequest.username,
                    password: defaultRequest.password,
                    withCredentials: defaultRequest.withCredentials,
                    responseFormat: rf,
                    timeout: defaultRequest.timeout
                });
            };
        };
    };
};
var put_ = function (driver) {
    return function (url) {
        var $85 = map2($$void);
        var $86 = put(driver)(Affjax_ResponseFormat.ignore)(url);
        return function ($87) {
            return $85($86($87));
        };
    };
};
export {
    defaultRequest,
    RequestContentError,
    ResponseBodyError,
    TimeoutError,
    RequestFailedError,
    XHROtherError,
    printError,
    request,
    get,
    post,
    post_,
    put,
    put_,
    $$delete as delete,
    delete_,
    patch,
    patch_
};
