// Generated by purs version 0.15.4
import * as Foreign_Daisyui from "../Foreign.Daisyui/index.js";
import * as React_Basic from "../React.Basic/index.js";
import * as React_Basic_DOM from "../React.Basic.DOM/index.js";
import * as React_Basic_DOM_Generated from "../React.Basic.DOM.Generated/index.js";
import * as React_Icons from "../React.Icons/index.js";
import * as React_Icons_Fa from "../React.Icons.Fa/index.js";
var a = /* #__PURE__ */ React_Basic_DOM_Generated.a();
var icon = /* #__PURE__ */ React_Icons.icon();
var linkIcon = function (title) {
    return function (reactIcon) {
        return function (href) {
            return a({
                href: href,
                children: [ icon(reactIcon)({
                    size: "24px",
                    title: title
                }) ]
            });
        };
    };
};
var appFooter = /* #__PURE__ */ React_Basic.element(Foreign_Daisyui.footer)({
    className: "p-6 footer footer-center gap-4 bg-base-200 text-base-content",
    children: [ /* #__PURE__ */ React_Basic_DOM_Generated.div()({
        className: "grid grid-flow-col gap-4",
        children: [ /* #__PURE__ */ linkIcon("Github")(React_Icons_Fa.faGithub)("https://github.com/andys8/type-signature-com"), /* #__PURE__ */ linkIcon("Twitter")(React_Icons_Fa.faTwitter)("https://twitter.com/_andys8") ]
    }), /* #__PURE__ */ React_Basic_DOM_Generated.p()({
        className: "select-none",
        children: [ /* #__PURE__ */ React_Basic_DOM.text("Copyright \xa9 2022 - type-signature.com") ]
    }) ]
});
export {
    appFooter
};
