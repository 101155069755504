// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind_Indexed from "../Control.Bind.Indexed/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Effect from "../Effect/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var apply = /* #__PURE__ */ Control_Apply.apply(Effect.applyEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var Render = function (x) {
    return x;
};
var unsafeRenderEffect = Render;
var unsafeHook = Render;
var semigroupRender = function (dictTypeEquals) {
    return function (dictSemigroup) {
        var append = Data_Semigroup.append(Effect.semigroupEffect(dictSemigroup));
        return {
            append: function (v) {
                return function (v1) {
                    return append(v)(v1);
                };
            }
        };
    };
};
var monoidRender = function (dictTypeEquals) {
    var semigroupRender1 = semigroupRender(dictTypeEquals);
    return function (dictMonoid) {
        var semigroupRender2 = semigroupRender1(dictMonoid.Semigroup0());
        return {
            mempty: Data_Monoid.mempty(Effect.monoidEffect(dictMonoid)),
            Semigroup0: function () {
                return semigroupRender2;
            }
        };
    };
};
var ixFunctorRender = {
    imap: function (f) {
        return function (v) {
            return map(f)(v);
        };
    }
};
var ixApplyRender = {
    iapply: function (v) {
        return function (v1) {
            return apply(v)(v1);
        };
    },
    IxFunctor0: function () {
        return ixFunctorRender;
    }
};
var ixBindRender = {
    ibind: function (v) {
        return function (f) {
            return function __do() {
                var a = v();
                var v1 = f(a);
                return v1();
            };
        };
    },
    IxApply0: function () {
        return ixApplyRender;
    }
};
var ixApplicativeRender = {
    ipure: function (a) {
        return pure(a);
    },
    IxApply0: function () {
        return ixApplyRender;
    }
};
var ixMonadRender = {
    IxApplicative0: function () {
        return ixApplicativeRender;
    },
    IxBind1: function () {
        return ixBindRender;
    }
};
var functorRender = {
    map: function (f) {
        return function (v) {
            return map(f)(v);
        };
    }
};
var discard = function (dictIxBind) {
    return Control_Bind_Indexed.ibind(dictIxBind);
};
var coerceHook = function () {
    return function (v) {
        return v;
    };
};
var bind = function (dictIxBind) {
    return Control_Bind_Indexed.ibind(dictIxBind);
};
var applyRender = function (dictTypeEquals) {
    return {
        apply: function (v) {
            return function (v1) {
                return apply(v)(v1);
            };
        },
        Functor0: function () {
            return functorRender;
        }
    };
};
var bindRender = function (dictTypeEquals) {
    var applyRender1 = applyRender(dictTypeEquals);
    return {
        bind: function (v) {
            return function (f) {
                return function __do() {
                    var a = v();
                    var v1 = f(a);
                    return v1();
                };
            };
        },
        Apply0: function () {
            return applyRender1;
        }
    };
};
var applicativeRender = function (dictTypeEquals) {
    var applyRender1 = applyRender(dictTypeEquals);
    return {
        pure: function (a) {
            return pure(a);
        },
        Apply0: function () {
            return applyRender1;
        }
    };
};
var monadRender = function (dictTypeEquals) {
    var applicativeRender1 = applicativeRender(dictTypeEquals);
    var bindRender1 = bindRender(dictTypeEquals);
    return {
        Applicative0: function () {
            return applicativeRender1;
        },
        Bind1: function () {
            return bindRender1;
        }
    };
};
export {
    coerceHook,
    unsafeHook,
    unsafeRenderEffect,
    bind,
    discard,
    ixFunctorRender,
    ixApplyRender,
    ixApplicativeRender,
    ixBindRender,
    ixMonadRender,
    functorRender,
    applyRender,
    applicativeRender,
    bindRender,
    monadRender,
    semigroupRender,
    monoidRender
};
