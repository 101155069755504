// Generated by purs version 0.15.4
import * as React_Basic from "../React.Basic/index.js";
var icon = function () {
    return function (reactIcon) {
        return function (props) {
            return React_Basic.element(reactIcon)(props);
        };
    };
};
var icon1 = /* #__PURE__ */ icon();
var icon_ = function (reactIcon) {
    return icon1(reactIcon)({});
};
export {
    icon,
    icon_
};
