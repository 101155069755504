// Generated by purs version 0.15.4
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Error_Class from "../Control.Monad.Error.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Function_Uncurried from "../Data.Function.Uncurried/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
import * as React_Basic_Hooks from "../React.Basic.Hooks/index.js";
import * as React_Basic_Hooks_Internal from "../React.Basic.Hooks.Internal/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
var coerceHook = /* #__PURE__ */ React_Basic_Hooks_Internal.coerceHook();
var bind = /* #__PURE__ */ React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender);
var useMemo = /* #__PURE__ */ React_Basic_Hooks.useMemo(React_Basic_Hooks.eqUnsafeReference);
var discard = /* #__PURE__ */ React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender);
var useEffect = /* #__PURE__ */ React_Basic_Hooks.useEffect(React_Basic_Hooks.eqUnsafeReference);
var for_ = /* #__PURE__ */ Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(/* #__PURE__ */ Effect.monoidEffect(/* #__PURE__ */ Effect.monoidEffect(Data_Monoid.monoidUnit)));
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl));
var $$try = /* #__PURE__ */ Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var throwError = /* #__PURE__ */ Control_Monad_Error_Class.throwError(Control_Monad_Error_Class.monadThrowEffect);
var UseAff = function (x) {
    return x;
};
var AffReducer = function (x) {
    return x;
};
var UseAffReducer = function (x) {
    return x;
};
var runAffReducer = function (v) {
    return Data_Function_Uncurried.runFn2(v);
};
var ntUseAffReducer = {
    Coercible0: function () {
        return undefined;
    }
};
var useAffReducer = function (initialState) {
    return function (affReducer) {
        return coerceHook(bind(useMemo(affReducer)(function (v) {
            return Effect_Unsafe.unsafePerformEffect(React_Basic_Hooks.mkReducer(function (v1) {
                return runAffReducer(affReducer)(v1.state);
            }));
        }))(function (reducer$prime) {
            return bind(React_Basic_Hooks.useReducer({
                state: initialState,
                effects: [  ]
            })(reducer$prime))(function (v) {
                return discard(useEffect(v.value0.effects)(function __do() {
                    for_(v.value0.effects)(function (aff) {
                        return Effect_Aff.launchAff_(bind1(aff)(function (actions) {
                            return liftEffect(for_(actions)(v.value1));
                        }));
                    })();
                    return mempty();
                }))(function () {
                    return pure(new Data_Tuple.Tuple(v.value0.state, v.value1));
                });
            });
        }));
    };
};
var ntUseAff = {
    Coercible0: function () {
        return undefined;
    }
};
var useAff = function (dictEq) {
    var useEffect1 = React_Basic_Hooks.useEffect(dictEq);
    return function (deps) {
        return function (aff) {
            return coerceHook(bind(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v) {
                return discard(useEffect1(deps)(function __do() {
                    v.value1(Data_Function["const"](Data_Maybe.Nothing.value))();
                    var fiber = Effect_Aff.launchAff(bind1($$try(aff))(function (r) {
                        return liftEffect(v.value1(function (v1) {
                            return new Data_Maybe.Just(r);
                        }));
                    }))();
                    return Effect_Aff.launchAff_(Effect_Aff.killFiber(Effect_Exception.error("Stale request cancelled"))(fiber));
                }))(function () {
                    return React_Basic_Hooks_Internal.unsafeRenderEffect((function () {
                        if (v.value0 instanceof Data_Maybe.Just && v.value0.value0 instanceof Data_Either.Left) {
                            return throwError(v.value0.value0.value0);
                        };
                        if (v.value0 instanceof Data_Maybe.Just && v.value0.value0 instanceof Data_Either.Right) {
                            return pure1(new Data_Maybe.Just(v.value0.value0.value0));
                        };
                        if (v.value0 instanceof Data_Maybe.Nothing) {
                            return pure1(Data_Maybe.Nothing.value);
                        };
                        throw new Error("Failed pattern match at React.Basic.Hooks.Aff (line 53, column 24 - line 56, column 30): " + [ v.value0.constructor.name ]);
                    })());
                });
            }));
        };
    };
};
var noEffects = function (state) {
    return {
        state: state,
        effects: [  ]
    };
};
var mkAffReducer = function ($45) {
    return pure1(AffReducer(Data_Function_Uncurried.mkFn2($45)));
};
export {
    useAff,
    UseAff,
    useAffReducer,
    mkAffReducer,
    runAffReducer,
    noEffects,
    UseAffReducer,
    ntUseAff,
    ntUseAffReducer
};
