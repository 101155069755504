// Generated by purs version 0.15.4
import * as $foreign from "./foreign.js";
import * as React_Basic from "../React.Basic/index.js";
var button_ = function () {
    return React_Basic.element($foreign.button);
};
export {
    button,
    footer,
    footerTitle,
    hero,
    heroOverlay,
    heroContent,
    navbar,
    navbarStart,
    navbarCenter,
    navbarEnd,
    kbd,
    badge,
    progress,
    alert,
    codeMockup,
    stats,
    stat,
    statItem,
    select,
    selectOption,
    buttonGroup
} from "./foreign.js";
export {
    button_
};
